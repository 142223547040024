
import React, { useState, useRef, useEffect } from "react";

function SelectDropdown({ label, options, name, id, onChange = () => {}, className, customWidth, customPadding, customMargin }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options.length > 0 ? options[0] : { value: '', label: 'Select' });
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange({ target: { name, value: option.value } });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`flex flex-col gap-y-1 bg-white ${className}`} ref={dropdownRef}>
      <label htmlFor={id} className="text-base font-bold font-mulish text-gray-1">
        {label}
      </label>
      <div className={`relative border px-3 focus-within:ring-1 ring-black rounded-lg bg-white ${customWidth ? customWidth : ''} ${customPadding ? customPadding : ''}`}>
        <button 
          type="button" // Prevent form submission
          className="w-full outline-none py-2 caret-custom-blue group bg-white flex justify-between items-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="whitespace-nowrap">{selectedOption.label}</span>
          <svg
            className="w-4 h-4 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>
        {isOpen && (
          <ul className={`absolute right-0 mt-2 w-full border rounded-lg bg-white shadow-lg z-10 max-h-60 overflow-auto ${customMargin ? customMargin : ''}`}>
            {options.length === 0 ? (
              <li className="px-3 py-2 cursor-not-allowed text-gray-500">No Records</li>
            ) : (
              options.map((option, index) => (
                <li
                  key={index}
                  className={`px-3 py-2 cursor-pointer hover:bg-gray-100 ${option.value === selectedOption.value ? 'bg-gray-200' : ''}`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </li>
              ))
            )}
          </ul>
        )}
      </div>
    </div>
  );
}

export default SelectDropdown;