import { Blueline, Greenline, Plus, Plusfour } from "assets/svgs/Index";
export const attandence_activity = [
    {
      login_time: "18:05",
      login: "LOGGED OUT",
      present_time: "8 H 5 Min",
      svg: <Blueline />,
      bg_color: "bg-[#edf2ff]",
    },
    {
      login_time: "09:00",
      login: "LOGIN",
      present_time: "Present",
      svg: <Greenline />,
      bg_color: "bg-[#e5f7eb]",
    },
    {
      login_time: "18:05",
      login: "LOGGED OUT",
      present_time: "8 H 5 Min",
      svg: <Blueline />,
      bg_color: "bg-[#edf2ff]",
    },
    {
      login_time: "09:00",
      login: "LOGIN",
      present_time: "Present",
      svg: <Greenline />,
      bg_color: "bg-[#e5f7eb]",
    },
    {
      login_time: "18:05",
      login: "LOGGED OUT",
      present_time: "8 H 5 Min",
      svg: <Blueline />,
      bg_color: "bg-[#edf2ff]",
    },
    {
      login_time: "09:00",
      login: "LOGIN",
      present_time: "Present",
      svg: <Greenline />,
      bg_color: "bg-[#e5f7eb]",
    },
    {
      login_time: "18:05",
      login: "LOGGED OUT",
      present_time: "8 H 5 Min",
      svg: <Blueline />,
      bg_color: "bg-[#edf2ff]",
    },
    {
      login_time: "09:00",
      login: "LOGIN",
      present_time: "Present",
      svg: <Greenline />,
      bg_color: "bg-[#e5f7eb]",
    },
    {
      login_time: "18:05",
      login: "LOGGED OUT",
      present_time: "8 H 5 Min",
      svg: <Blueline />,
      bg_color: "bg-[#edf2ff]",
    },
    {
      login_time: "09:00",
      login: "LOGIN",
      present_time: "Present",
      svg: <Greenline />,
      bg_color: "bg-[#e5f7eb]",
    },
  ];
