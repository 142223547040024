import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import SuccessfulDialog from "../../../../components/Elements/SuccessfulDialog";
import { Crossiconssvg } from "assets/svgs/Index";
import { addSkillsApi, createEmployeeApi } from "api/taskManagement";
import Select from "react-select";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

const ActiveStep5 = ({
  prevStep,
  handleNextClick,
  activeStep,
  setAddEmployee,
  showModal,
  setAllFormData,
  allFormData,
  takedata,
}) => {
  // const [skills, setSkills] = useState([]);
  const [allskills, setAllSkills] = useState([]);
  // const [skill, setSkill] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); // State to store API response
  const [saveOption, setSaveOption] = useState([]);
  // const addSkillsSendToAPI = saveOption.map((item) => item.value); // Correct this part

  console.log("allskills", allskills);

  console.log("saveoption", saveOption);
  // console.log("addSkillsSendToAPI", addSkillsSendToAPI);
  const formik = useFormik({
    initialValues: {
      skills: [],
    },
    validationSchema: Yup.object({
      skills: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required("Skill cannot be empty"),
          })
        )
        .min(1, "At least one skill is required"),
    }),
    onSubmit: (values) => {
      setAllFormData((prev) => ({
        ...prev,
        skills: saveOption,
      }));
      console.log("Form Submitted with data:", values.skills);
    },
  });

  useEffect(() => {
    const takedata = async () => {
      try {
        const response = await addSkillsApi();
        console.log("responsingle", response);
        setAllSkills(response.response.data); // Store the response data in state
      } catch (error) {
        console.log("error fetching data:", error);
      }
    };
    takedata();
  }, []); // Re-run the effect if id changes

  // const handleShowPopup = () => {
  //   setAddEmployee(true);
  // };

  // const handleRemoveSkill = (removedSkill) => {
  //   const updatedSkills = skills.filter((s) => s.name !== removedSkill.name);
  //   setSkills(updatedSkills);
  //   formik.setFieldValue("skills", updatedSkills);
  // };

  const handleSubmit = async (values) => {
    try {
      // Create a new FormData object
      const formData = new FormData();

      // Append your form fields to FormData
      formData.append("account_number", allFormData?.accountNumber);
      formData.append(
        "graduation_certificate",
        allFormData?.graduationCertificate
      );
      formData.append("basic_salary", allFormData?.basicSalary);
      formData.append("date_of_birth", allFormData?.dateOfBirth);
      formData.append("employee_type", allFormData?.employeeType);
      formData.append("joining_date", allFormData?.dateOfJoining);
      formData.append("department", allFormData?.department);
      formData.append("email", allFormData?.email);
      formData.append("experience_letter", allFormData?.experienceLetter);
      formData.append("food_allowance", allFormData?.foodAllowance);
      formData.append("full_name", allFormData?.fullName);
      formData.append("house_allowance", allFormData?.houseAllowance);
      formData.append("identity_proof", allFormData?.identityProof);
      formData.append("ifsc_code", allFormData?.ifscCode);
      formData.append("upload_image", allFormData?.image); // `yourImageFile` should be the file object (e.g., `File` object from input)
      formData.append("Marital_status", allFormData?.maritalStatus);
      formData.append("name_on_account", allFormData?.nameOnAccount);
      formData.append("offer_letter", allFormData?.offerLetter);
      formData.append("phone_number", allFormData?.phoneNumber);
      formData.append("position", allFormData?.position);
      formData.append("probation_period", allFormData?.probationPeriod);
      // formData.append("add_skills", allFormData?.addSkillsSendToAPI); // JSON if array of objects
      // formData.append("add_skills", Array(values.addSkills));
      if (values.addSkills && values.addSkills.length > 0) {
        formData.append("add_skills", JSON.stringify(values.addSkills)); // Send as a JSON string
      }
      formData.append("national_tax_id", allFormData?.taxId);
      formData.append("transport_allowance", allFormData?.transportAllowance);
      formData.append("unique_id_number", allFormData?.uniqueIdNumber);

      // Send the form data using your API method
      const response = await createEmployeeApi(formData);
      toast.success(response?.response?.message);
      setAddEmployee(false);
      takedata();
      console.log("API Response:", response);

    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error("Error submitting data:", error);
      // Handle error
    } finally {
      setAddEmployee(false);
    }
  };

  // const handleSubmit = (values) => {
  //   console.log("Submitted values:", values);
  // };

  return (
    <>
      <label className="block text-[16px] font-mulish font-bold text-gray-1 mb-5">
        Add Skills
      </label>
      <div className="flex max-h-[650px]">
        <div className="w-full">
          <Formik
            initialValues={{ addSkills: [] }} // Initial values for multi-select
            onSubmit={handleSubmit} // Handle form submission
          >
            {({ setFieldValue, values }) => (
              <Form className="w-full">
                <Field name="addSkills">
                  {({ field }) => (
                    <Select
                      isMulti
                      name="addSkills"
                      options={allskills}
                      value={allskills.filter((option) =>
                        values.addSkills.includes(option.value)
                      )}
                      onChange={(selectedOptions) => {
                        setFieldValue(
                          "addSkills",
                          selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : []
                        );
                      }}
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                  )}
                </Field>

                {/* <button
                  type="submit"
                  className="mt-4 p-2 bg-blue-500 text-white rounded"
                >
                  Submit
                </button> */}
                <div className="w-full flex justify-center items-center mt-10">
                  <button
                    type="submit"
                    className="lg:text-base w-[162px] text-sm font-mulish font-bold 
            py-[14px] px-[24px] bg-btn-gradient text-white rounded-lg
            shadow-sm hover:bg-red-600 focus:outline-none"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {apiResponse && (
        <div className="mt-4 p-4 border border-gray-200 rounded-md">
          <h2 className="text-lg font-bold">API Response Data:</h2>
          <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
        </div>
      )}

      <SuccessfulDialog
        heading={"Employee added successfully"}
        show={showPopup}
        onClose={() => setShowPopup(false)}
      />
    </>
  );
};

export default ActiveStep5;
