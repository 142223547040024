import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoaderEmployeeHR = () => {
  return (
    <div className="rounded-lg shadow-lg p-4 bg-white">
      <div className="flex items-center gap-x-4 gap-y-5 mb-4">
        <Skeleton circle={true} height={90} width={90} />
        <div className="flex flex-col gap-x-2 gap-y-2 w-2/3">
          <Skeleton height={20} width="100%" />
          <Skeleton height={15} width="75%" />
        </div>
      </div>
      <div className="text-gray-600 mb-4 flex flex-col justify-center 2xl:px-[49px] sm:px-[10px]">
        <div className="flex justify-between">
          <Skeleton height={20} width="25%" />
          <Skeleton height={20} width="33%" />
        </div>
        <div className="flex justify-between mt-2">
          <Skeleton height={20} width="25%" />
          <Skeleton height={20} width="25%" />
        </div>
      </div>
      <div className="bg-[#f5f5f5] p-2 rounded-xl mb-4 px-[18px]">
        <Skeleton height={20} width="75%" className="mb-2" />
        <Skeleton height={20} width="66%" className="mb-2" />
      </div>
      <div className="flex justify-center items-center">
        <Skeleton height={40} width="25%" />
      </div>
    </div>
  );
};

export default SkeletonLoaderEmployeeHR;
