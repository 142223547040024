import React, { useState, useEffect } from "react";
import Svgs from "../../../../assets/svgs/Index";
import RoundedCard from "components/Elements/RoundedCard";

const BankInformation = ({ userdata }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    bankAccountNo: userdata?.account_number || null,
    ifscCode: userdata?.ifsc_code || null,
    panNo: "HFESW4532U", // hardcoded value
    bankName: userdata?.bank_name || null,
    nameOnAccount: userdata?.name_on_account || null,
  });

  console.log("userdata===>>>>>>", userdata);

  // Update formData when userdata changes
  useEffect(() => {
    if (userdata) {
      setFormData((prevData) => ({
        ...prevData,
        bankAccountNo: userdata?.account_number || null,
        ifscCode: userdata?.ifsc_code || null,
        bankName: userdata?.bank_name || null,
        nameOnAccount: userdata?.name_on_account || null,
      }));
    }
  }, [userdata]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    setIsEditing(false); // Switch back to non-editable mode
    // You can send the updated formData to your API here if needed
  };

  return (
    <div>
      <RoundedCard>
        <div className="flex items-center justify-between">
          <h1 className="text-xl font-semibold font-public-sans text-gray-1">
            Bank Information
          </h1>
          <div
            onClick={isEditing ? handleSave : toggleEditing}
            className="cursor-pointer"
          >
            {isEditing ? (
              <span className="text-red-500 font-semibold">Save</span>
            ) : (
              <Svgs.Editicon />
            )}
          </div>
        </div>
        <div className="mt-6">
          {/* Bank Account No. */}
          <div className="border-b flex items-center justify-between py-3 max-h-[43px]">
            <h1 className="text-xs font-normal font-public-sans text-gray-2 uppercase w-1/3">
              Bank Account No.
            </h1>
            <div>
              {isEditing ? (
                <input
                  type="text"
                  name="bankAccountNo"
                  value={formData.bankAccountNo || ""}
                  onChange={handleChange}
                  className="text-sm font-medium font-public-sans text-gray-1 outline-none text-right"
                />
              ) : (
                <h1 className="text-sm font-medium font-public-sans text-gray-1">
                  {formData.bankAccountNo || "N/A"}
                </h1>
              )}
            </div>
          </div>

          {/* IFSC Code */}
          <div className="border-b flex items-center justify-between py-3 max-h-[43px]">
            <h1 className="text-xs font-normal font-public-sans text-gray-2 uppercase w-1/3">
              IFSC Code
            </h1>
            <div>
              {isEditing ? (
                <input
                  type="text"
                  name="ifscCode"
                  value={formData.ifscCode || ""}
                  onChange={handleChange}
                  className="text-sm font-medium font-public-sans text-gray-1 outline-none text-right"
                />
              ) : (
                <h1 className="text-sm font-medium font-public-sans text-gray-1">
                  {formData.ifscCode || "N/A"}
                </h1>
              )}
            </div>
          </div>

          {/* PAN No. */}
          <div className="border-b flex items-center justify-between py-3 max-h-[43px]">
            <h1 className="text-xs font-normal font-public-sans text-gray-2 uppercase w-1/3">
              PAN No.
            </h1>
            <div>
              {isEditing ? (
                <input
                  type="text"
                  name="panNo"
                  value={formData.panNo || ""}
                  onChange={handleChange}
                  className="text-sm font-medium font-public-sans text-gray-1 outline-none text-right"
                />
              ) : (
                <h1 className="text-sm font-medium font-public-sans text-gray-1">
                  {formData.panNo || "N/A"}
                </h1>
              )}
            </div>
          </div>

          {/* Bank Name */}
          <div className="border-b flex items-center justify-between py-3 max-h-[43px]">
            <h1 className="text-xs font-normal font-public-sans text-gray-2 uppercase w-1/3">
              Bank Name
            </h1>
            <div>
              {isEditing ? (
                <input
                  type="text"
                  name="bankName"
                  value={formData.bankName || ""}
                  onChange={handleChange}
                  className="text-sm font-medium font-public-sans text-gray-1 outline-none text-right"
                />
              ) : (
                <h1 className="text-sm font-medium font-public-sans text-gray-1">
                  {formData.bankName || "N/A"}
                </h1>
              )}
            </div>
          </div>

          {/* Name on Account */}
          <div className="border-b flex items-center justify-between py-3 max-h-[43px]">
            <h1 className="text-xs font-normal font-public-sans text-gray-2 uppercase w-1/3">
              Name on Account
            </h1>
            <div>
              {isEditing ? (
                <input
                  type="text"
                  name="nameOnAccount"
                  value={formData.nameOnAccount || ""}
                  onChange={handleChange}
                  className="text-sm font-medium font-public-sans text-gray-1 outline-none text-right"
                />
              ) : (
                <h1 className="text-sm font-medium font-public-sans text-gray-1">
                  {formData.nameOnAccount || "N/A"}
                </h1>
              )}
            </div>
          </div>
        </div>
      </RoundedCard>
    </div>
  );
};

export default BankInformation;
