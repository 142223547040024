import { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser
      ? JSON.parse(storedUser)
      : {
          isLoggedIn: false,
          role: "",
          token: null, // Add token to user state
          tokenExpiry: null, // Add token expiry to user state
        };
  });

  // Function to logout user
  const logoutUser = () => {
    setUser({
      isLoggedIn: false,
      role: "",
      token: null,
      tokenExpiry: null,
    });
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("tokenExpiry");
  };

  // Check token expiration and auto-logout if needed
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (user.token && user.tokenExpiry) {
        const now = new Date().getTime();
        if (now >= user.tokenExpiry) {
          logoutUser();
        }
      }
    };

    // Run the check on initial load
    checkTokenExpiration();

    // Set up an interval to check token expiry periodically (e.g., every minute)
    const intervalId = setInterval(checkTokenExpiration, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [user]);

  // Update localStorage whenever the user state changes
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

export default UserContext;
