import React, { useEffect, useState } from "react";
import RoundedCard from "components/Elements/RoundedCard";
import Svgs from "assets/svgs/Index";
import { Layout } from "components/Layout/DashboardLayout";
import Button from "components/Button";
import { useNavigate, useParams } from "react-router-dom";

import SelectDropdown from "components/SelectDropdown";
import PersonalInfo from "../../UserDashboard/MyProfile/EmployeeDetailCards/PersonalInfo";
import BankInformation from "./EmployeeDetailCards/BankInformation";
import Skills from "../../UserDashboard/MyProfile/EmployeeDetailCards/Skills";
import Documents from "../../UserDashboard/MyProfile/EmployeeDetailCards/Documents";
import SalarayInfo from "../../UserDashboard/MyProfile/EmployeeDetailCards/SalarayInfo";
import axios from "axios";
import { singleEmployeeApi } from "api/taskManagement";
import SalarayInfouser from "../../UserDashboard/MyProfile/EmployeeDetailCards/SalarayInfo";

function MyProfileUser() {
  const { id } = useParams();
  console.log("id=====>params", id);
  const [addEmployee, setAddEmployee] = useState(false);

  const [userdata, setGetdata] = useState(null);
  console.log("userdata", userdata);

  const loginUserID = localStorage.getItem("userId");

  console.log("loginUserID", loginUserID && loginUserID);
  useEffect(() => {
    const takedata = async () => {
      try {
        const response = await singleEmployeeApi(loginUserID);
        console.log("responsingle", response);
        setGetdata(response.response.data); // Store the response data in state
      } catch (error) {
        console.log("error fetching data:", error);
      }
    };
    takedata();
  }, [loginUserID]); // Re-run the effect if id changes

  const handleShowPopup = () => {
    setAddEmployee(true);
  };

  const handleClosePopup = () => {
    setAddEmployee(false);
  };

  const roleOptions = [
    { value: "personal info", label: "Personal Info" },
    { value: "bank information", label: "Bank information" },
    { value: "Skills", label: "Skills" },
    { value: "salary information", label: "Salary information" },
    { value: "documents", label: "Documents" },
  ];

  const nevigate = useNavigate();
  return (
    <>
      <Layout active={"My profile"}>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-[32px]">
          <RoundedCard>
            <div className="flex justify-between flex-wrap ">
              <div className="flex items-center gap-5">
                {/* Image or Fallback */}
                <div className="flex justify-center items-center w-[90px] h-[90px] rounded-full bg-blue-300">
                  {userdata?.upload_image ? (
                    <img
                      src={userdata.upload_image}
                      alt={userdata?.user?.full_name || "User"}
                      className="w-full h-full rounded-full"
                    />
                  ) : (
                    <span className="text-[31px] font-bold text-white">
                      {userdata?.user?.full_name?.charAt(0).toUpperCase() ||
                        "U"}
                    </span>
                  )}
                </div>

                {/* Text Content */}
                <div className="flex flex-col gap-[10px]">
                  <h1 className="text-xl font-semibold font-public-sans text-gray-1">
                    {userdata?.user?.full_name || "N/A"}
                  </h1>
                  <h1 className="text-sm font-medium font-public-sans text-[#497CFF] bg-[#ecf1fe] rounded-full py-[5px] px-[12px] text-center">
                    {(() => {
                      const positionMap = {
                        JR: "Junior",
                        SR: "Senior",
                        MG: "Manager",
                        PM: "Project Manager",
                      };

                      return positionMap[userdata?.position] || "N/A";
                    })()}
                  </h1>
                </div>
              </div>
              <h1 className="mt-3 text-sm font-medium font-public-sans text-[#497CFF] mx-4">
                {" "}
                {userdata?.employee_type || "N/A"}
              </h1>
            </div>

            <div className=" px-6 my-4">
              <div className="flex items-center justify-between">
                <h1 className=" text-xs font-normal font-public-sans text-gray-4">
                  DEPARTMENT
                </h1>
                <h1 className=" text-sm font-medium font-public-sans text-gray-1">
                  {userdata?.department}
                </h1>
              </div>
              <div className="flex items-center justify-between">
                <h1 className=" text-xs font-normal font-public-sans text-gray-4">
                  DATE OF JOINING
                </h1>
                <h1 className=" text-sm font-medium font-public-sans text-gray-1">
                  {userdata?.joining_date}
                </h1>
              </div>
            </div>
            <div className=" rounded-xl border p-4 bg-[#f5f5f5]">
              <div className="flex items-center gap-2">
                <span>
                  <Svgs.Email />
                </span>
                <h1 className=" text-sm font-medium font-public-sans text-gray-2">
                  {userdata?.user?.email}
                </h1>
              </div>
              <div className="py-3">
                <hr className=" border" />
              </div>
              <div className="flex items-center gap-2">
                <span>
                  <Svgs.Textmessage />
                </span>
                <h1 className=" text-sm font-medium font-public-sans text-gray-2">
                  12 Apr, 2024
                </h1>
              </div>
            </div>
          </RoundedCard>

          <PersonalInfo userdata={userdata} />
          <BankInformation userdata={userdata} />
        </div>
        <div className="grid grid-cols-12 my-6 gap-5">
          <div className=" col-span-12 md:col-span-8">
            <Skills userdata={userdata} />

            <Documents userdata={userdata} />
          </div>
          <div className=" col-span-12 md:col-span-4">
            <SalarayInfouser />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default MyProfileUser;
