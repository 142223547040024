import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectDropdown from "components/SelectDropdown";
import svgs from "../../../../assets/svgs/Index";
import { singleDepartmentApi } from "api/taskManagement";

const ActiveStep0 = ({
  prevsStep,
  activeStep,
  handleNextClick,
  setAllFormData,
  allFormData,
}) => {
  // const departmentOptions = [
  //   { value: "digital_marketing", label: "Digital Marketing" },
  //   { value: "development", label: "Development" },
  //   { value: "web design", label: "Web Design" },
  //   { value: "gGraphic & UI/UX design", label: "Graphic & UI/UX Design" },
  // ];

  const positionOptions = [
    { value: "JR", label: "Junior" },
    { value: "SR", label: "Senior" },
    { value: "MG", label: "Manager" },
    { value: "PM", label: "Project Manager" },
  ];

  const employeeTypeOptions = [
    { value: "onsite", label: "Onsite" },
    { value: "remote", label: "Remote" },
    { value: "hybrid", label: "Hybrid" },
    { value: "flexible", label: "Flexible" },
    { value: "other", label: "Other" },
  ];

  const [departmentOptions, setDepartmentOption] = useState([]);
  console.log("departmentOption", departmentOptions);

  useEffect(() => {
    const takedata = async () => {
      try {
        const response = await singleDepartmentApi();
        console.log("responsingle", response);
        setDepartmentOption(response.response.data); // Store the response data in state
      } catch (error) {
        console.log("error fetching data:", error);
      }
    };
    takedata();
  }, []); //

  // Validation schema
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full Name is required"),
    department: Yup.string().required("Department is required"),
    position: Yup.string().required("Position is required"),
    dateOfJoining: Yup.date()
      .nullable()
      .required("Date of joining is required"),
    employeeType: Yup.string().required("Employee Type is required"),
    phoneNumber: Yup.string()
      .matches(/^\+?\d{10,17}$/, "Invalid phone number format")
      .required("Phone number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  return (
    <div className="max-h-[650px]">
      <Formik
        initialValues={{
          fullName: "",
          department: "",
          position: "",
          dateOfJoining: null,
          employeeType: "",
          phoneNumber: "",
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log("Form Submitted:", values);
          setAllFormData(values);
          handleNextClick();
        }}
      >
        {({ setFieldValue, values, isValid }) => (
          <Form>
            {/* Full Name */}
            <div>
              <label className="block text-[16px] font-mulish font-bold text-gray-1">
                Full Name
              </label>
              <Field
                type="text"
                name="fullName"
                placeholder="Enter Employee Name"
                className="text-gray-4 lg:text-base font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm text-sm"
              />
              <ErrorMessage
                name="fullName"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* Department & Position */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-[24px]">
              <div>
                <SelectDropdown
                  label={"Department"}
                  options={departmentOptions}
                  selectClass={"w-full"}
                  className={"w-full"}
                  customPadding={"px-4 py-[4.5px]"}
                  customargin={"mt-3"}
                  onChange={(selectedOption) =>
                    setFieldValue("department", selectedOption.target.value)
                  }
                />
                <ErrorMessage
                  name="department"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <SelectDropdown
                  label={"Position"}
                  options={positionOptions}
                  selectClass={"w-full"}
                  className={"w-full"}
                  customPadding={"px-4 py-[4.5px]"}
                  customargin={"mt-3"}
                  onChange={(selectedOption) =>
                    setFieldValue("position", selectedOption.target.value)
                  }
                />
                <ErrorMessage
                  name="position"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
            </div>

            {/* Date of Joining & Employee Type */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-[24px]">
              <div>
                <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
                  Date of Joining
                </label>
                <div className="relative">
                  <DatePicker
                    selected={values.dateOfJoining}
                    onChange={(date) => setFieldValue("dateOfJoining", date)}
                    placeholderText="Select your date"
                    className="form-control lg:text-base text-sm font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm pr-10"
                  />
                  <ErrorMessage
                    name="dateOfJoining"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() =>
                      document.querySelector(".form-control").focus()
                    }
                  >
                    <svgs.CalendarIcon />
                  </div>
                </div>
              </div>
              <div className="lg:mt-1">
                <SelectDropdown
                  label={"Employee Type"}
                  options={employeeTypeOptions}
                  selectClass={"w-full"}
                  className={"w-full"}
                  customPadding={"px-4 py-[4.5px]"}
                  customargin={"mt-3"}
                  onChange={(selectedOption) =>
                    setFieldValue("employeeType", selectedOption.target.value)
                  }
                />
                <ErrorMessage
                  name="employeeType"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
            </div>

            {/* Phone Number & Email */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-[24px]">
              <div>
                <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
                  Phone Number
                </label>
                <Field
                  type="tel"
                  name="phoneNumber"
                  placeholder="+1 000 000 0000"
                  className="lg:text-base text-sm font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm"
                  maxLength="17"
                />
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
                  Email ID
                </label>
                <Field
                  type="email"
                  name="email"
                  placeholder="Enter Employee Email"
                  className="lg:text-base text-sm font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
            </div>

            {/* Buttons */}
            <div className="flex justify-center mt-[32px] gap-[10px]">
              <button
                onClick={prevsStep}
                disabled={activeStep === 0}
                className={`${
                  activeStep === 0 && "hidden"
                } lg:text-base w-[162px] text-sm font-mulish font-bold py-[14px] px-[24px] bg-gray-300 text-gray-500 rounded-lg shadow-sm hover:bg-gray-400`}
              >
                Previous
              </button>
              <button
                type="submit"
                disabled={!isValid}
                className={`lg:text-base w-[162px] text-sm font-mulish font-bold py-[14px] px-[24px] rounded-lg shadow-sm ${
                  isValid
                    ? "bg-btn-gradient text-white hover:bg-red-600"
                    : "bg-gray-300 text-gray-500"
                }`}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ActiveStep0;
