import React, { useState } from "react";
import Svgs from "../../../../assets/svgs/Index";
import RoundedCard from "components/Elements/RoundedCard";
import DocumentModal from "../Elements/DocumentModal";

const Documents = ({userdata}) => {
  const [documentModal, setDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); // State to hold the selected document details

  const documents = [
    {
      documentsname: "Offer letter",
      svg: <Svgs.Pdficon />,
      editsvg: <Svgs.Editicon />,
    },
    {
      documentsname: "12th certificate copy",
      svg: <Svgs.Pdficon />,
      editsvg: <Svgs.Editicon />,
    },
    {
      documentsname: "Resume",
      svg: <Svgs.Pdficon />,
      editsvg: <Svgs.Editicon />,
    },
    {
      documentsname: "Graduation degree",
      svg: <Svgs.Pdficon />,
      editsvg: <Svgs.Editicon />,
    },
    {
      documentsname: "Experience letter 1",
      svg: <Svgs.Pdficon />,
      editsvg: <Svgs.Editicon />,
    },
    {
      documentsname: "Experience letter 2",
      svg: <Svgs.Pdficon />,
      editsvg: <Svgs.Editicon />,
    },
  ];

  return (
    <div>
      <RoundedCard className={"my-6"}>
        <div>
          <h1 className="text-xl font-semibold font-public-sans text-gray-1">
            Documents
          </h1>
        </div>

        <div className="my-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          {documents.map((value, index) => (
            <div
              key={index}
              className="px-[16px] py-[18px] bg-[#f5f5f5] border border-gray-5 rounded-xl shadow flex items-center justify-between min-w-[200px] h-[52px] my-2 cursor-pointer"
            >
              <div
                className="flex items-center gap-[14px]"
                onClick={() => {
                  setDocumentModal(true);
                  setSelectedDocument(value.documentsname); // Set the clicked document
                }}
              >
                <span>{value.svg}</span>
                <h1 className="text-sm font-medium font-public-sans text-[#17243E]">
                  {value.documentsname}
                </h1>
              </div>
              <div>{value.editsvg}</div>
            </div>
          ))}
        </div>
      </RoundedCard>

      {documentModal && (
        <DocumentModal
        userdata={userdata}
          show={documentModal}
          onClose={() => setDocumentModal(false)}
          documentName={selectedDocument} // Pass the selected document name
        />
      )}
    </div>
  );
};

export default Documents;
