import React, { useEffect, useState } from "react";
import { Fileattachsvg } from "assets/svgs/Index";
import SuccessfulDialog from "components/Elements/SuccessfulDialog";
import "react-datepicker/dist/react-datepicker.css";
import ActiveStep0 from "../AddEmployeeData/ActiveStep0";
import ActiveStep1 from "../AddEmployeeData/ActiveStep1";
import ActiveStep2 from "../AddEmployeeData/ActiveStep2";
import ActiveStep3 from "../AddEmployeeData/ActiveStep3";
import ActiveStep4 from "../AddEmployeeData/ActiveStep4";
import ActiveStep5 from "../AddEmployeeData/ActiveStep5";

function Addnewemployee({ onClose, showModal, setAddEmployee, takedata }) {
  const [allFormData, setAllFormData] = useState({});

  console.log("allFormData", allFormData);
  // const formik = useFormik();
  const [formData, setFormData] = useState({
    name: "",
    department: "",
    position: "",
    dateOfJoining: "",
    phoneNumber: "",
    emailId: "",
    identityProof: "",
    uniqueIdNumber: "",
    dateOfBirth: "",
    maritalStatus: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const headings = [
    "Basic info",
    "Personal info",
    "Salary",
    "Documents",
    "Bank details",
    "Skills",
  ];

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    "Basic info",
    "Personal info",
    " Add Salary",
    "Documents",
    "Bank details",
    "Skills",
  ];

  const handleNextClick = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const prevsStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleFileChange = (e, label) => {
    const file = e.target.files[0];
    if (file) {
      console.log(`${label} uploaded:`, file.name);
    }
  };
  // date inputr uderef

  const [startDates, setStartDates] = useState(null);

  const FileUpload = ({ label }) => {
    return (
      <div className="flex flex-col ">
        <div>
          <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish ">
            {label}
          </label>
        </div>
        <div className="flex px-[16px] py-[12.5px] border border-gray-500 rounded-lg shadow-sm   mt-[8px] ">
          <input
            type="file"
            onChange={(e) => handleFileChange(e, label)}
            className="hidden"
            id={label.replace(/\s+/g, "-").toLowerCase()}
          />
          <label
            htmlFor={label.replace(/\s+/g, "-").toLowerCase()}
            className="flex-grow cursor-pointer text-gray-500"
          >
            Upload
          </label>
          <label
            htmlFor={label.replace(/\s+/g, "-").toLowerCase()}
            className="cursor-pointer text-red-500"
          >
            <Fileattachsvg />
          </label>
        </div>
      </div>
    );
  };

  const [skills, setSkills] = useState([]);
  const [skill, setSkill] = useState("");

  // Function to add skills, supports adding multiple skills separated by commas or spaces
  const handleAddSkill = () => {
    const newSkills = skill
      .split(/[\s,]+/) // Split by space or comma
      .map((s) => s.trim()) // Trim white spaces
      .filter((s) => s && !skills.includes(s)); // Remove duplicates and empty entries

    if (newSkills.length) {
      setSkills([...skills, ...newSkills]); // Add new skills to state
      setSkill(""); // Clear the input
    }
  };

  // Function to remove a specific skill
  const handleRemoveSkill = (removedSkill) => {
    setSkills(skills.filter((s) => s !== removedSkill)); // Remove the clicked skill
  };
  const [showPopup, setShowsPopup] = useState(false);

  const handleShowPopup = () => {
    setShowsPopup(true);
  };

  const handleClosePopup = () => {
    setShowsPopup(false);
    onClose();
  };

  const roleOptions = [
    { value: "single", label: "Single" },
    { value: "married", label: "Married" },
  ];

  const valueOptions = [
    { value: "select", label: "Select" },
    { value: "passport", label: "Passport" },
    { value: "driving license", label: "Driving License" },
    { value: "aadhaar", label: "Aadhaar" },
  ];
  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
      {!showPopup ? (
        <div className="flex justify-center rounded-[16px]">
          <div className="bg-white p-[24px]  w-full overflow-y-auto rounded-[16px]">
            {activeStep === 0 && (
              <h2 className="lg:text-[24px] text-[18px]  font-semibold mb-6 text-center text-gray-1 font-public-sans">
                Basic info
              </h2>
            )}
            {activeStep === 1 && (
              <h2 className="lg:text-[24px] text-[18px]  font-semibold mb-6 text-center text-gray-1 font-public-sans">
                Personal info
              </h2>
            )}
            {activeStep === 2 && (
              <h2 className="lg:text-[24px] text-[18px]  font-semibold mb-6 text-center text-gray-1 font-public-sans">
                 Add Salary
              </h2>
            )}
            {activeStep === 3 && (
              <h2 className="lg:text-[24px] text-[18px]  font-semibold mb-6 text-center text-gray-1 font-public-sans">
                Upload documents
              </h2>
            )}
            {activeStep === 4 && (
              <h2 className="lg:text-[24px] text-[18px]  font-semibold mb-6 text-center text-gray-1 font-public-sans">
                Bank details
              </h2>
            )}
            {activeStep === 5 && (
              <h2 className="lg:text-[24px] text-[18px]  font-semibold mb-6 text-center text-gray-1 font-public-sans">
                Add skills
              </h2>
            )}
            <div className="flex justify-between relative  gap-[10px]  overflow-x-auto ">
              {steps.map((step, index) => {
                const isActive = index === activeStep;
                const stepNumber = index + 1;

                return (
                  isActive && (
                    <div
                      key={index}
                      className="flex-1 items-center relative justify-center  sm:hidden"
                    >
                      <div className="flex flex-col items-center justify-center overflow-x-auto min-w-[80px]">
                        <div
                          className={`cursor-pointer rounded-full w-[40px] h-[40px] flex items-center justify-center mx-auto lg:text-[20px] text-[16px] font-public-sans font-medium px-[13.5px] py-[8px] ${
                            isActive
                              ? "bg-btn-gradient text-white"
                              : "bg-gray-5 text-gray-4"
                          } z-10 shadow-md`}
                        >
                          {/* Show step number */}
                          <h2 className={`hidden sm:block`}>{stepNumber}</h2>
                          <h2 className={`block sm:hidden`}>{stepNumber}</h2>
                        </div>

                        <div
                          className={`py-[8px] text-[12px] font-medium font-public-sans ${
                            isActive
                              ? "text-transparent bg-clip-text bg-btn-gradient"
                              : "text-gray-2 hidden"
                          }`}
                        >
                          {/* Show step description */}
                          <div className="hidden sm:block">{step}</div>
                          <h2 className={`sm:hidden`}>
                            {
                              [
                                "Basic info",
                                "Personal info",
                                "Add Salary",
                                "Documents",
                                "Bank details",
                                "Skills",
                              ][index]
                            }
                          </h2>
                        </div>
                      </div>

                      {/* Display connecting line if not the last step */}
                      {index < steps.length - 1 && (
                        <div
                          className={`absolute inset-1/4 left-1/2 w-full h-0.5 transform -translate-y-1/4 hidden sm:block`}
                        >
                          <div
                            className={`flex-grow h-fit border-t-2 border-dashed ${
                              isActive ? "border-[#f8595c]" : "border-[#828282]"
                            }`}
                          ></div>
                        </div>
                      )}
                    </div>
                  )
                );
              })}
            </div>
            <div className="flex justify-between relative sm:py-[32px] gap-[10px]  overflow-x-auto ">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="flex-1 items-center  relative justify-center  hidden sm:block"
                >
                  <div className="flex flex-col items-center justify-center overflow-x-auto min-w-[80px]">
                    <div
                      className={`cursor-pointer rounded-full w-[40px] h-[40px] g flex items-center justify-center mx-auto lg:text-[20px] text-[16px] font-public-sans
                  font-medium px-[13.5px] py-[8px] 
                  ${
                    index <= activeStep
                      ? "bg-btn-gradient text-white"
                      : "bg-gray-5 text-gray-4"
                  } z-10 shadow-md `}
                    >
                      {index + 1}
                    </div>
                    <div
                      className={`py-[8px]  text-[12px] font-medium font-public-sans
                  ${
                    index <= activeStep
                      ? "text-transparent bg-clip-text bg-btn-gradient"
                      : "text-gray-2 "
                  } `}
                    >
                      {step}
                    </div>
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`  absolute inset-1/4 left-1/2  w-full h-0.5  transform -translate-y-1/4`}
                    >
                      <div
                        className={`flex-grow h-fit border-t-2 border-dashed ${
                          index < activeStep
                            ? "border-[#f8595c]"
                            : " border-[#828282]"
                        }`}
                      ></div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {activeStep === 0 && (
              <ActiveStep0
                prevsStep={prevsStep}
                activeStep={activeStep}
                handleNextClick={handleNextClick}
                steps={steps}
                handleShowPopup={handleShowPopup}
                allFormData={allFormData}
                setAllFormData={setAllFormData}
              />
            )}

            {activeStep === 1 && (
              <ActiveStep1
                prevsStep={prevsStep}
                activeStep={activeStep}
                handleNextClick={handleNextClick}
                steps={steps}
                handleShowPopup={handleShowPopup}
                allFormData={allFormData}
                setAllFormData={setAllFormData}
              />
            )}

            {activeStep === 2 && (
              <ActiveStep2
                prevsStep={prevsStep}
                activeStep={activeStep}
                handleNextClick={handleNextClick}
                steps={steps}
                handleShowPopup={handleShowPopup}
                allFormData={allFormData}
                setAllFormData={setAllFormData}
              />
            )}

            {activeStep === 3 && (
              <ActiveStep3
                prevsStep={prevsStep}
                activeStep={activeStep}
                handleNextClick={handleNextClick}
                steps={steps}
                handleShowPopup={handleShowPopup}
                allFormData={allFormData}
                setAllFormData={setAllFormData}
              />
            )}

            {activeStep === 4 && (
              <ActiveStep4
                prevsStep={prevsStep}
                activeStep={activeStep}
                handleNextClick={handleNextClick}
                steps={steps}
                handleShowPopup={handleShowPopup}
                allFormData={allFormData}
                setAllFormData={setAllFormData}
              />
            )}

            {activeStep === 5 && (
              <ActiveStep5
                setAddEmployee={setAddEmployee}
                prevsStep={prevsStep}
                activeStep={activeStep}
                handleNextClick={handleNextClick}
                steps={steps}
                handleShowPopup={handleShowPopup}
                showModal={showModal}
                allFormData={allFormData}
                setAllFormData={setAllFormData}
                takedata={takedata}
              />
            )}
          </div>
        </div>
      ) : (
        <SuccessfulDialog
          heading={"Employee added successfully"}
          show={showPopup}
          onClose={handleClosePopup}
        />
      )}
    </>
  );
}

export default Addnewemployee;
