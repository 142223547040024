import React, { useState, useEffect } from 'react';
import Svgs from '../../../../assets/svgs/Index';
import RoundedCard from 'components/Elements/RoundedCard';

const PersonalInfo = ({ userdata }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    passportNo: "QW3342981", // hardcoded value
    passportExpDate: "12/12/28", // hardcoded value
    phoneNumber: userdata?.user?.phone_number || '',
    dob: userdata?.date_of_birth || '',
    maritalStatus: userdata?.Marital_status || '',
  });

  // Update formData when userdata changes
  useEffect(() => {
    if (userdata) {
      setFormData((prevData) => ({
        ...prevData,
        phoneNumber: userdata?.user?.phone_number || '',
        dob: userdata?.date_of_birth || '',
        maritalStatus: userdata?.Marital_status || '',
      }));
    }
  }, [userdata]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    setIsEditing(false); // Switch back to non-editable mode
    // You can send the updated formData to your API here if needed
  };

  return (
    <div>
      <RoundedCard>
        <div className="flex items-center justify-between">
          <h1 className="text-xl font-semibold font-public-sans text-gray-1">
            Personal Info
          </h1>
          <div
            onClick={isEditing ? handleSave : toggleEditing}
            className="cursor-pointer"
          >
            {isEditing ? (
              <span className="text-red-500 font-semibold">Save </span>
            ) : (
              <Svgs.Editicon />
            )}
          </div>
        </div>
        <div className="mt-6">
          {/* Passport No. */}
          <div className="border-b flex items-center justify-between py-3 max-h-[43px]">
            <h1 className="text-xs font-normal font-public-sans text-gray-2 uppercase ">
              Passport No.
            </h1>
            <div className="">
              {isEditing ? (
                <input
                  type="text"
                  name="passportNo"
                  value={formData.passportNo}
                  onChange={handleChange}
                  className="text-sm font-medium font-public-sans text-gray-1 outline-none text-right"
                />
              ) : (
                <h1 className="text-sm font-medium font-public-sans text-gray-1">
                  {formData.passportNo}
                </h1>
              )}
            </div>
          </div>

          {/* Passport Exp Date */}
          <div className="border-b flex items-center justify-between py-3 max-h-[43px]">
            <h1 className="text-xs font-normal font-public-sans text-gray-2 uppercase w-1/3">
              Passport Exp Date.
            </h1>
            <div className="">
              {isEditing ? (
                <input
                  type="text"
                  name="passportExpDate"
                  value={formData?.passportExpDate}
                  onChange={handleChange}
                  className="text-sm font-medium font-public-sans text-gray-1 outline-none text-right"
                />
              ) : (
                <h1 className="text-sm font-medium font-public-sans text-gray-1">
                  {formData?.passportExpDate}
                </h1>
              )}
            </div>
          </div>

          {/* Phone Number */}
          <div className="border-b flex items-center justify-between py-3 max-h-[43px]">
            <h1 className="text-xs font-normal font-public-sans text-gray-2 uppercase w-1/3">
              Phone Number
            </h1>
            <div className="">
              {isEditing ? (
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData?.phoneNumber}
                  onChange={handleChange}
                  className="text-sm font-medium font-public-sans text-gray-1 outline-none text-right"
                />
              ) : (
                <h1 className="text-sm font-medium font-public-sans text-gray-1">
                  {formData?.phoneNumber}
                </h1>
              )}
            </div>
          </div>

          {/* Date of Birth */}
          <div className="border-b flex items-center justify-between py-3 max-h-[43px]">
            <h1 className="text-xs font-normal font-public-sans text-gray-2 uppercase w-1/3">
              Date of Birth
            </h1>
            <div className="">
              {isEditing ? (
                <input
                  type="text"
                  name="dob"
                  value={formData?.dob}
                  onChange={handleChange}
                  className="text-sm font-medium font-public-sans text-gray-1 outline-none text-right"
                />
              ) : (
                <h1 className="text-sm font-medium font-public-sans text-gray-1">
                  {formData?.dob}
                </h1>
              )}
            </div>
          </div>

          {/* Marital Status */}
          <div className="border-b flex items-center justify-between py-3 max-h-[43px]">
            <h1 className="text-xs font-normal font-public-sans text-gray-2 uppercase w-1/3">
              Marital Status
            </h1>
            <div className="">
              {isEditing ? (
                <input
                  type="text"
                  name="maritalStatus"
                  value={formData?.maritalStatus}
                  onChange={handleChange}
                  className="text-sm font-medium font-public-sans text-gray-1 outline-none text-right"
                />
              ) : (
                <h1 className="text-sm font-medium font-public-sans text-gray-1">
                  {formData?.maritalStatus}
                </h1>
              )}
            </div>
          </div>
        </div>
      </RoundedCard>
    </div>
  );
};

export default PersonalInfo;
