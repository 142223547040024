import React, { useState, useEffect } from "react";
import Svgs from "assets/svgs/Index";
import { useNavigate, useParams } from "react-router-dom";
import { useSidebarContext } from "Context/ContextLayout.jsx";
import { singleEmployeeApi } from "api/taskManagement";

export const NavBar = () => {
  const { sidebarOpen, setSidebarOpen } = useSidebarContext();

  const { id } = useParams();
  console.log("id=====>params", id);
  const [addEmployee, setAddEmployee] = useState(false);

  const [userdata, setGetdata] = useState(null);
  console.log("userdata", userdata);

  useEffect(() => {
    const takedata = async () => {
      try {
        const response = await singleEmployeeApi(id);
        console.log("responsingle", response);
        setGetdata(response.response.data); // Store the response data in sta
      } catch (error) {
        console.log("error fetching data:", error);
      }
    };
    takedata();
  }, [id]);
  const navigate = useNavigate();
  return (
    <nav className="bg-white lg:border-b-2 px-4 sm:px-6 md:px-8 py-4">
      <div className="flex flex-row items-center justify-between">
        {/* Logo and Menu Button */}
        <div className="flex flex-row items-center space-x-4 md:gap-24">
          <img
            onClick={() => {
              navigate("/");
            }}
            src={"/images/LogoMR.png"}
            alt=""
            className="w-[36px] h-[24px]  lg:w-[60px] lg:h-[40px] cursor-pointer"
          />
          <button
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
            className="hidden xl:block cursor-pointer"
          >
            <Svgs.BackArrowDouble
              className={`w-5 h-5 transition-transform duration-500 ${
                sidebarOpen ? "" : "rotate-180"
              }`}
            />
          </button>
        </div>

        {/* Search and Profile */}
        <div className="flex items-center  space-x-4 md:space-x-8 w-2/5 justify-end">
          <span className=" block md:hidden">
            <Svgs.SearchBar />
          </span>
          <div className="hidden md:flex justify-center lg:gap-4 sm:bg-gray-100 rounded-lg items-center py-2 px-4 w-[268px]">
            <span>
              <Svgs.SearchBar />
            </span>
            <input
              className="bg-transparent focus:outline-none w-full text-sm font-normal font-public-sans text-gray-4"
              type="text"
              placeholder="Search"
            />
          </div>
          <div className="flex flex-row space-x-3 lg:space-x-4 items-center cursor-pointer">
            <span className="flex items-center justify-center h-6 w-6 md:h-8 md:w-8">
              <Svgs.Notification />
            </span>
            <div
              onClick={() => {
                navigate("/user/my-profile");
              }}
              className=" hidden md:block"
            >
              {/* <img
                src={"/images/profilePic.png"}
                alt=""
                className=" w-6 h-6 md:h-8 md:w-8 object-contain cursor-pointer"
              /> */}
              {userdata?.upload_image ? (
                <img
                  src={userdata.upload_image}
                  alt={userdata?.user?.full_name || "User"}
                  className=" w-6 h-6 md:h-8 md:w-8 object-contain cursor-pointer"
                />
              ) : (
                <span className="w-6 h-6 md:h-10 md:w-10 border rounded-full flex justify-center items-center text-base font-medium text-black">
                  {userdata?.user?.full_name?.charAt(0).toUpperCase() || "N/A"}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
