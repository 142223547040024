import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

const ActiveStep4 = ({
  prevsStep,
  activeStep,
  handleNextClick,
  steps,
  handleShowPopup,
  setAllFormData,
  allFormData,
}) => {
  const formik = useFormik({
    initialValues: {
      taxId: "",
      accountNumber: "",
      ifscCode: "",
      bankName: "",
      nameOnAccount: "",
    },
    validationSchema: Yup.object({
      taxId: Yup.string().required("National Tax ID is required"),
      accountNumber: Yup.string().required("Account number is required"),
      ifscCode: Yup.string().required("IFSC code is required"),
      bankName: Yup.string().required("Bank name is required"),
      nameOnAccount: Yup.string().required("Name on account is required"),
    }),
    onSubmit: (values) => {
      setAllFormData((prev) => ({
        ...prev,
        ...values,
      }));
      console.log("Form Submitted with data:", values);
    },
  });

  // Log updated state when it changes
  useEffect(() => {
    console.log("Updated allFormData:", allFormData);
  }, [allFormData]);

  return (
    <div className="max-h-[746px]">
      <form onSubmit={formik.handleSubmit}>
        {/* National Tax ID */}
        <div>
          <label className="block text-[16px] font-mulish font-bold text-gray-1">
            National Tax ID
          </label>
          <input
            type="text"
            name="taxId"
            placeholder="Enter National Tax ID"
            value={formik.values.taxId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`text-gray-4 lg:text-base font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border ${
              formik.errors.taxId && formik.touched.taxId
                ? "border-red-500"
                : "border-gray-5"
            } rounded-lg shadow-sm text-sm`}
          />
          {formik.touched.taxId && formik.errors.taxId && (
            <p className="text-red-500 text-sm mt-1">{formik.errors.taxId}</p>
          )}
        </div>

        {/* Account Number */}
        <div className="mt-6">
          <label className="block text-[16px] font-mulish font-bold text-gray-1">
            Account Number
          </label>
          <input
            type="text"
            name="accountNumber"
            placeholder="Enter Account Number"
            value={formik.values.accountNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`text-gray-4 lg:text-base font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border ${
              formik.errors.accountNumber && formik.touched.accountNumber
                ? "border-red-500"
                : "border-gray-5"
            } rounded-lg shadow-sm text-sm`}
          />
          {formik.touched.accountNumber && formik.errors.accountNumber && (
            <p className="text-red-500 text-sm mt-1">
              {formik.errors.accountNumber}
            </p>
          )}
        </div>

        {/* IFSC Code */}
        <div className="mt-6">
          <label className="block text-[16px] font-mulish font-bold text-gray-1">
            IFSC Code
          </label>
          <input
            type="text"
            name="ifscCode"
            placeholder="Enter IFSC Code"
            value={formik.values.ifscCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`text-gray-4 lg:text-base font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border ${
              formik.errors.ifscCode && formik.touched.ifscCode
                ? "border-red-500"
                : "border-gray-5"
            } rounded-lg shadow-sm text-sm`}
          />
          {formik.touched.ifscCode && formik.errors.ifscCode && (
            <p className="text-red-500 text-sm mt-1">
              {formik.errors.ifscCode}
            </p>
          )}
        </div>

        {/* Bank Name */}
        <div className="mt-6">
          <label className="block text-[16px] font-mulish font-bold text-gray-1">
            Bank Name
          </label>
          <input
            type="text"
            name="bankName"
            placeholder="Enter Bank Name"
            value={formik.values.bankName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`text-gray-4 lg:text-base font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border ${
              formik.errors.bankName && formik.touched.bankName
                ? "border-red-500"
                : "border-gray-5"
            } rounded-lg shadow-sm text-sm`}
          />
          {formik.touched.bankName && formik.errors.bankName && (
            <p className="text-red-500 text-sm mt-1">
              {formik.errors.bankName}
            </p>
          )}
        </div>

        {/* Name on Account */}
        <div className="mt-6">
          <label className="block text-[16px] font-mulish font-bold text-gray-1">
            Name on Account
          </label>
          <input
            type="text"
            name="nameOnAccount"
            placeholder="Enter Name on Account"
            value={formik.values.nameOnAccount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`text-gray-4 lg:text-base font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border ${
              formik.errors.nameOnAccount && formik.touched.nameOnAccount
                ? "border-red-500"
                : "border-gray-5"
            } rounded-lg shadow-sm text-sm`}
          />
          {formik.touched.nameOnAccount && formik.errors.nameOnAccount && (
            <p className="text-red-500 text-sm mt-1">
              {formik.errors.nameOnAccount}
            </p>
          )}
        </div>

        {/* Back and Next Buttons */}
        <div className="flex justify-center mt-[32px] gap-[10px] ">
          {/* Back Button */}
          <button
            type="button"
            onClick={prevsStep}
            disabled={activeStep === 0}
            className={`${
              activeStep === 0 ? "hidden" : ""
            } w-[162px] py-[14px] px-[24px] font-mulish 
            border rounded-lg border-[#f65154] font-bold lg:text-base text-sm text-transparent bg-clip-text bg-btn-gradient`}
          >
            Back
          </button>

          {/* Next Button */}
          <button
            type="button"
            onClick={async () => {
              formik.setTouched({
                taxId: true,
                accountNumber: true,
                ifscCode: true,
                bankName: true,
                nameOnAccount: true,
              });

              const errors = await formik.validateForm();

              if (Object.keys(errors).length === 0) {
                setAllFormData((prev) => ({
                  ...prev,
                  ...formik.values,
                }));
                console.log("Form data submitted:", formik.values);
                handleNextClick();
              } else {
                console.log("Validation Errors:", errors);
              }
            }}
            className="lg:text-base w-[162px] text-sm font-mulish font-bold 
            py-[14px] px-[24px] bg-btn-gradient text-white rounded-lg
            shadow-sm hover:bg-red-600 focus:outline-none"
          >
            {activeStep === 5 ? "Submit" : "Next"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ActiveStep4;
