import React, { useEffect, useState } from "react";
import { Email, Phone } from "assets/svgs/Index.js";
import { Layout } from "components/Layout/DashboardLayout";
import ShareGroceryDialog from "./Elements/ShareGroceryDialog.jsx";
import Button from "components/Button";
import Svgs from "assets/svgs/Index.js";
import RoundedCard from "components/Elements/RoundedCard.jsx";
import { useNavigate } from "react-router-dom";
import SelectDropdown from "components/SelectDropdown.jsx";
import SkeletonLoaderEmployeeHR from "components/Skeleton/SkeletonLoaderEmployeeHR.jsx";
import { allemployeeApi } from "api/taskManagement.js";

function EmployeesHR() {
  const [showPopup, setShowPopup] = useState(false);
  const [userdata, setGetdata] = useState(null);
  const [addEmployee, setAddEmployee] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(""); // State for the selected filter

  console.log("userdata====>>>", userdata);

  const takedata = async () => {
    try {
      const response = await allemployeeApi();
      setGetdata(response.response.data); // Store the response data in state
    } catch (error) {
      console.log("error fetching data:", error);
    }
  };
  useEffect(() => {
    takedata();
  }, []);

  const handleShowPopup = () => {
    setAddEmployee(true);
  };

  const handleClosePopup = () => {
    setAddEmployee(false);
  };

  useEffect(() => {
    if (showPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showPopup]);

  const filterOptions = [
    { value: "", label: "All" }, // Default option to show all employees
    { value: "JR", label: "Junior" },
    { value: "SR", label: "Senior" },
    { value: "MG", label: "Manager" },
    { value: "PM", label: "Project Manager" },
  ];

  const handleFilterChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    setSelectedFilter(selectedOption.target.value);
  };

  const filteredEmployees = selectedFilter
    ? userdata?.filter((employee) => employee?.position === selectedFilter)
    : userdata;

  console.log("filteredEmployees", filteredEmployees);
  const nevigate = useNavigate();

  return (
    <Layout active={"Employees"}>
      <div className="min-h-screen">
        <div className="flex gap-[10px] sm:gap-[0px] flex-wrap sm:flex-row justify-between items-start sm:items-center mb-4 sm:space-y-0">
          <h2 className="lg:text-2xl sm:text-lg text-base font-bold font-public-sans text-gray-1">
            Employees
          </h2>
          <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="md:text-sm text-xs font-public-sans font-semibold text-gray-1">
              <SelectDropdown
                label=""
                options={filterOptions}
                name="role"
                id="role"
                selectClass={"w-full"}
                className={"w-[205px]"}
                onChange={handleFilterChange} // Handle dropdown change
              />
            </div>

            <div className="">
              <Button
                handleClick={handleShowPopup}
                className={"flex items-center gap-2 py-[8px] px-[16px] "}
                text={
                  <>
                    {" "}
                    <Svgs.Plus /> Add new employee
                  </>
                }
              />
              {addEmployee && (
                <ShareGroceryDialog
                  setAddEmployee={setAddEmployee}
                  show={setAddEmployee}
                  onClose={handleClosePopup}
                  takedata={takedata}
                />
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 py-5 gap-y-4 gap-x-4">
          {filteredEmployees ? (
            filteredEmployees.length > 0 ? (
              filteredEmployees.map((employee, index) => (
                <RoundedCard key={index}>
                  <div className="">
                    <div className="flex justify-between flex-wrap">
                      <div className="flex items-center gap-x-4 gap-y-5 mb-4">
                        {employee?.upload_image ? (
                          <img
                            src={employee?.upload_image}
                            alt={employee?.user?.full_name}
                            className="rounded-full w-[90px] h-[90px] object-cover"
                          />
                        ) : (
                          <div className="rounded-full w-[90px] h-[90px] flex object-cover text-[32px] items-center justify-center bg-blue-300 text-white font-bold">
                            {employee?.user?.full_name?.[0]?.toUpperCase()}
                          </div>
                        )}
                        <div className="flex flex-col gap-x-2 gap-y-2">
                          <h3 className="lg:text-xl text-base font-semibold text-gray-1 font-public-sans">
                            {employee?.user?.full_name}
                          </h3>

                          <span className="flex justify-center items-center text-[#497CFF] border border-[#ecf1fe] py-[5px] px-[12px] rounded-[100px] bg-[#ecf1fe]   font-public-sans font-medium md:text-sm text-xs">
                            {(() => {
                              const positionMap = {
                                JR: "Junior",
                                SR: "Senior",
                                MG: "Manager",
                                PM: "Project Manager",
                              };

                              return positionMap[employee?.position] || "N/A";
                            })()}
                          </span>
                        </div>
                      </div>
                      <h1 className="mt-3 text-sm font-medium font-public-sans text-[#497CFF] mx-4">
                        {employee?.employee_type
                          ? employee?.employee_type
                          : "N/A"}
                      </h1>
                    </div>
                    <div className="text-gray-600 mb-4 flex flex-col justify-center 2xl:px-[49px] sm:px-[10px] ">
                      <div className="flex justify-between">
                        <p className="font-public-sans text-[12px] text-gray-4">
                          DEPARTMENT
                        </p>
                        <p className="font-public-sans text-[14px] text-gray-1 font-medium">
                          {employee?.department}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="font-public-sans text-[12px] text-gray-4">
                          TASK SCORE
                        </p>
                        <p className="font-medium text-[#00B037] text-[14px] font-public-sans">
                          {employee?.taskScore || "Loading..."}
                        </p>
                      </div>
                    </div>
                    <div className="bg-[#f5f5f5] p-2 rounded-xl mb-4 px-[18px]">
                      <p className="flex items-center space-x-2">
                        <span>
                          <Email />
                        </span>
                        <span className="text-gray-2 font-public-sans font-medium text-sm">
                          {employee?.user?.email}
                        </span>
                      </p>
                      <hr className="flex justify-center items-center mt-2" />
                      <p className="flex items-center space-x-2 mt-2">
                        <span>
                          <Phone />
                        </span>
                        <span className="text-gray-2 font-public-sans font-medium text-sm">
                          {employee?.user?.phone_number}
                        </span>
                      </p>
                    </div>
                    <div className="flex justify-center items-center">
                      <button
                        onClick={() =>
                          nevigate(`/hr/employeesdetails/${employee?.user?.id}`)
                        }
                        className="hover:text-white hover:border-none hover:bg-[#555353] 
                        py-[7px] px-[12.5px] flex justify-center items-center h-[30px] hover:scale-105 ease-in-out duration-300 bg-white border border-gray-2 rounded-lg shadow-sm text-sm font-semibold text-gray-2 font-public-sans"
                      >
                        View details
                      </button>
                    </div>
                  </div>
                </RoundedCard>
              ))
            ) : (
              <p>No employees found</p>
            )
          ) : (
            Array(6)
              .fill(0)
              .map((_, index) => <SkeletonLoaderEmployeeHR key={index} />)
          )}
        </div>
      </div>
    </Layout>
  );
}

export default EmployeesHR;
