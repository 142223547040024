import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  try {
    // Ensure the token is not added for specific endpoints if necessary
    if (config.url.includes("/register") || config.url === "/") {
      return config;
    }

    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");
    if (token) {
      // Set the Authorization header with the token
      config.headers.Authorization = `token ${token}`;
    }
    return config;
  } catch (err) {
    console.error("Error in request interceptor:", err);
    return Promise.reject(err); // Ensure to reject the promise on errors
  }
});

export const handleAPIRequest = async (requestFunc, endpoint, requestData) => {
  try {
    const { data } = await requestFunc(endpoint, requestData);
    console.log(`Request to ${endpoint} successful:`, data);
    return data;
  } catch (error) {
    const statusCode = error?.response?.status;
    const errorMessage =
      error?.response?.data?.message || error.message || "Unknown error";

    console.error(`Error in API request to ${endpoint}:`, errorMessage);

    if (statusCode === 401) {
      console.log("Unauthorized. Redirecting to login...");
      // window.location.href = "/"; // Redirect to the login page if unauthorized
    }

    throw error;
  }
};