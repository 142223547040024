import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import SuccessfulDialog from "../../../components/Elements/SuccessfulDialog";
import { Input } from "../../../components/Input";
import Button from "../../../components/Button.jsx";
import svgs from "../../../assets/svgs/Index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { applyFestivalLeaveApi, festivalLeaveApi } from "api/taskManagement"; // Assume this is the POST API

// Validation Schema
const validationSchema = Yup.object({
  festivalType: Yup.string().required("Festival type is required"),
  date: Yup.date().required("Date is required").nullable(),
  description: Yup.string()
    .required("Meeting notes are required")
    .min(10, "Description should be at least 10 characters"),
});

function FestivalLeaveDialog({ show, onClose }) {
  const [showPopup, setShowPopup] = useState(false);
  const [startDates, setStartDates] = useState(null);

  const popupRef = useRef();

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    onClose();
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (show || showPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show, showPopup]);

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  if (!show) return null;

  // Form Submission
  const handleFormSubmit = async (values) => {
    console.log("Form values", values);

    const formData = new FormData();
    formData.append("name", values.festivalType);
    formData.append("leave_date", values.date);
    formData.append("description", values.description);

    try {
      const response = await festivalLeaveApi(formData);
      console.log("Response", response);
      if (response.success) {
        handleShowPopup();
      } else {
        alert("Failed to apply festival leave.");
      }
    } catch (error) {
      console.error("Error", error);
      alert("Error occurred while applying festival leave.");
    }
  };

  return (
    <>
      {!showPopup ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="py-3">
            <div
              ref={popupRef}
              className="bg-white shadow-lg rounded-xl border p-6 mx-2 overflow-y-auto h-full md:w-[598px]"
            >
              <div className="flex flex-col gap-3">
                <div className="flex items-center justify-center text-center">
                  <h1 className="text-xl md:text-2xl font-semibold font-public-sans text-gray-700">
                    Festival Leave
                  </h1>
                </div>

                {/* Formik Form */}
                <Formik
                  initialValues={{
                    festivalType: "",
                    date: "",
                    description: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleFormSubmit} // Calling the form submit function
                >
                  {({
                    values,
                    handleChange,
                    setFieldValue,
                    errors,
                    touched,
                  }) => (
                    <Form>
                      <div>
                        <label
                          htmlFor="festivalType"
                          className="block text-sm font-bold text-gray-700"
                        >
                          Festival Type
                        </label>
                        <Field
                          as="input"
                          name="festivalType"
                          id="festivalType"
                          className="w-full mt-2 border rounded px-4 py-[10.5px]"
                        />
                        {errors.festivalType && touched.festivalType && (
                          <div className="text-red-500 text-sm">
                            {errors.festivalType}
                          </div>
                        )}
                      </div>

                      <div>
                        <label
                          htmlFor="date"
                          className="block text-sm font-bold text-gray-700 mt-4"
                        >
                          Date
                        </label>
                        <Field
                          type="date"
                          name="date"
                          className="w-full mt-2 border rounded px-4 py-[10.5px]"
                        />
                        {errors.date && touched.date && (
                          <div className="text-red-500 text-sm">
                            {errors.date}
                          </div>
                        )}
                      </div>

                      <div>
                        <label
                          htmlFor="description"
                          className="block text-sm font-bold text-gray-700 mt-4"
                        >
                          Meeting Notes
                        </label>
                        <Field
                          as="textarea"
                          name="description"
                          className="resize-none rounded-xl border w-full h-[130px] p-4 my-2 outline-none"
                          placeholder="Description"
                        />
                        {errors.description && touched.description && (
                          <div className="text-red-500 text-sm">
                            {errors.description}
                          </div>
                        )}
                      </div>

                      <div className="flex items-center justify-center gap-4 mt-6">
                        <Button
                          type="submit"
                          text="Apply"
                          className="w-[162px] h-[48px]"
                          customPadding="py-[14px] px-[24px]"
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SuccessfulDialog
          heading="Festival Leave applied successfully"
          show={showPopup}
          onClose={handleClosePopup}
        />
      )}
    </>
  );
}

export default FestivalLeaveDialog;
