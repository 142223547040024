import React, { useState } from "react";
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
} from "date-fns";
import Svgs from "../../../../assets/svgs/Index";

function Calendar({ setSelectedDate, selectedDate }) {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const renderHeader = () => {
    return (
      <div className="flex justify-between items-center mb-10">
        <div className="flex justify-between items-center w-full">
          <div>
            <h2 className="text-xl font-semibold font-public-sans text-gray-1">
              {format(currentMonth, "MMMM yyyy")}
            </h2>
          </div>
          <div className="flex gap-[18px] cursor-pointer">
            <div onClick={prevMonth}>
              <Svgs.LeftArrowicon />
            </div>
            <div onClick={nextMonth}>
              <Svgs.RightArrowicon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const days = [];
    const date = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          key={i}
          className="text-xs text-center font-semibold font-public-sans text-gray-1"
        >
          {date[i].toUpperCase()}
        </div>
      );
    }
    return <div className="grid grid-cols-7 mb-2">{days}</div>;
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, "d");
        const cloneDay = day;
        days.push(
          <div
            className={`text-center my-1 h-[32px] w-[32px] cursor-pointer sm:ml-2 ${
              !isSameMonth(day, monthStart)
                ? "text-gray-400 text-sm font-normal font-mulish"
                : ""
            } ${
              selectedDate && isSameDay(day, new Date(selectedDate))
                ? "bg-blue-500 text-white rounded-full h-[32px] w-[32px] flex justify-center items-center"
                : !selectedDate && isToday(day)
                ? "bg-blue-500 text-white rounded-full h-[32px] w-[32px] flex justify-center items-center"
                : ""
            }`}
            key={day}
            onClick={() => handleDateClick(cloneDay)}
          >
            <span>{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="grid grid-cols-7" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div>{rows}</div>;
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleDateClick = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setSelectedDate(formattedDate);
  };

  return (
    <div className="w-90 mx-auto p-4 rounded-lg border-b border-dashed">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
}

function isToday(date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

function isSameMonth(date, monthStart) {
  return date.getMonth() === monthStart.getMonth();
}

function isSameDay(date1, date2) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export default Calendar;
