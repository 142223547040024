import React, { useEffect, useState } from "react";
import Svgs from "../../../../assets/svgs/Index";
import RoundedCard from "components/Elements/RoundedCard";
import SalaryModal from "../Elements/SalaryModal";
import { singlesalrayApi } from "api/taskManagement";
import PaymentModal from "../Elements/PaymentModal";

const SalarayInfoHR = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSalary, setSelectedSalary] = useState(null);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  console.log("errorMessage", errorMessage);

  const salaryInfo = [
    {
      salarayslip: "Oct-24 salary slip",
      svg: <Svgs.Pdficon />,
      paymentslip: "Payment slip",
    },
    {
      salarayslip: "Sep-24 salary slip",
      svg: <Svgs.Pdficon />,
      paymentslip: "Payment slip",
    },
    {
      salarayslip: "Aug-24 salary slip",
      svg: <Svgs.Pdficon />,
      paymentslip: "Payment slip",
    },
    {
      salarayslip: "Jul-24 salary slip",
      svg: <Svgs.Pdficon />,
      paymentslip: "Payment slip",
    },
  ];

  // Handle opening the modal
  const openModal = (salary) => {
    setSelectedSalary(salary);
    setIsModalOpen(true);
  };

  const [singleSalray, setsSingleSalray] = useState();

  console.log("singleSalray=======>", singleSalray);

  const loginUserID = localStorage.getItem("userId");

  console.log("loginUserID", loginUserID && loginUserID);

  useEffect(() => {
    const takedata = async () => {
      try {
        const response = await singlesalrayApi(loginUserID);
        console.log("setsSingleSalray==>>>", response);
        setsSingleSalray(response.data); // Store the response data in state
      } catch (error) {
        setErrorMessage(error?.response?.data?.message);
        console.log("error fetching data:", error);
      }
    };
    takedata();
  }, []);

  return (
    <div>
      <RoundedCard>
        <div className="flex items-center justify-between">
          <h1 className=" text-xl font-semibold font-public-sans text-gray-1">
            Salary information
          </h1>
          {/* <Svgs.WritingPencil/> */}
        </div>
        <div className="mt-6">
          <div className=" border-b flex items-center justify-between py-[13px]">
            <h1 className=" text-xs font-normal font-public-sans text-gray-2 uppercase">
              Salary basis
            </h1>
            <h1 className=" text-sm font-medium font-public-sans text-gray-1">
              {singleSalray?.pay_type}
            </h1>
          </div>
          <div className=" border-b flex items-center justify-between py-[13px]">
            <h1 className=" text-xs font-normal font-public-sans text-gray-2 uppercase">
              Salary per month
            </h1>
            <h1 className=" text-sm font-medium font-public-sans text-gray-1">
              {singleSalray?.salary}
            </h1>
          </div>
          <div className=" border-b flex items-center justify-between py-[13px]">
            <h1 className=" text-xs font-normal font-public-sans text-gray-2 uppercase">
              effective date
            </h1>
            <h1 className=" text-sm font-medium font-public-sans text-gray-1">
              {singleSalray?.created_at}
            </h1>
          </div>
          <div className=" border-b flex items-center justify-between py-[13px]">
            <h1 className=" text-xs font-normal font-public-sans text-gray-2 uppercase">
              Payment type
            </h1>
            <h1 className=" text-sm font-medium font-public-sans text-gray-1">
              {singleSalray?.pay_type}
            </h1>
          </div>
          <div>
            {/* Map through salaryInfo */}
            <div className="overflow-y-auto h-[40px]">
              {/* {singleSalray.map((value, index) => ( */}
              <div>
                <div className="flex items-center lg:justify-between py-[13px] flex-wrap gap-[10px] md:justify-center justify-between ">
                  <h1
                    className="text-xs font-normal font-public-sans text-gray-2 uppercase flex justify-center items-center gap-[6px] cursor-pointer"
                    onClick={() => setIsModalOpen(true)}
                  >
                    {singleSalray?.created_at || "N/A"}
                    <span>
                      <Svgs.Pdficon />
                    </span>
                  </h1>
                  <span
                    onClick={() => setPaymentModalOpen(true)}
                    className="text-sm font-normal font-public-sans text-gray-2 flex items-center gap-[6px]  cursor-pointer"
                  >
                    <Svgs.Pdficon /> Payment slip
                  </span>
                </div>
              </div>
              {/* ))} */}
            </div>

            {/* Modal Component */}
            <SalaryModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              singleSalray={singleSalray}
            />
            <PaymentModal
              isOpen={paymentModalOpen}
              onClose={() => setPaymentModalOpen(false)}
              singleSalray={singleSalray}
            />
          </div>
        </div>
      </RoundedCard>
    </div>
  );
};

export default SalarayInfoHR;
