import React, { useRef } from "react";

const PaymentModal = ({ isOpen, onClose, singleSalray }) => {
  const modalRef = useRef(null);

  console.log("singleSalray", singleSalray);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      {/* Overlay */}
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50" />

      {/* Modal */}
      <div
        ref={modalRef}
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  rounded-lg   py-[50px] px-[48px] z-50 p-6 bg-white overflow-y-scroll"
      >
        <div>
          {singleSalray?.salary_slip ? (
            <img
              src={singleSalray?.salary_slip && singleSalray?.salary_slip}
              alt=""
            />
          ) : (
            "N/A"
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentModal;
