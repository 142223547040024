import { axiosInstance, handleAPIRequest } from "./axiosInstance";

export const signinApi = async (data) => {
  return await handleAPIRequest(axiosInstance.post, `api/signin`, data);
};

export const allemployeeApi = async () => {
  return await handleAPIRequest(axiosInstance.get, `api/allemployee`);
};

export const singleEmployeeApi = async (id) => {
  return await handleAPIRequest(axiosInstance.get, `api/employee/${id}/`);
};

export const addSkillsApi = async () => {
  return await handleAPIRequest(axiosInstance.get, `api/skills-list`);
};

export const createEmployeeApi = async (data) => {
  return await handleAPIRequest(
    axiosInstance.post,
    `api/create-employee/`,
    data
  );
};

export const logoutApi = async () => {
  return await handleAPIRequest(axiosInstance.post, `api/logout`);
};

export const applyLeaveApi = async (data) => {
  return await handleAPIRequest(axiosInstance.post, `api/leave_apply`, data);
};

export const festivalLeaveApi = async (data) => {
  return await handleAPIRequest(axiosInstance.post, `api/holiday/`, data);
};

export const officeTimeLeaveApi = async (data) => {
  return await handleAPIRequest(axiosInstance.post, `api/officetime/`, data);
};

export const attendanceEmployeeApi = async () => {
  return await handleAPIRequest(axiosInstance.get, `api/attendance-employee`);
};

export const presentEmployeeslistApi = async () => {
  return await handleAPIRequest(axiosInstance.get, `api/PresentEmployeeslist`);
};

export const attendanceSummaryApi = async (date) => {
  return await handleAPIRequest(
    axiosInstance.get,
    date ? `api/attendance-summary?date=${date}` : `api/attendance-summary`
  );
};

export const pendingleaveslistApi = async (id) => {
  return await handleAPIRequest(
    axiosInstance.get,
    id ? `api/pending-leaves-list?id=${id}` : `api/pending-leaves-list`
  );
};

export const approveleaveApi = async (id, data) => {
  return await handleAPIRequest(
    axiosInstance.patch,
    `api/approve_leave/${id}/`,
    data
  );
};

export const salaryListApi = async () => {
  return await handleAPIRequest(axiosInstance.get, `api/salary_list`);
};

export const myLeavesApi = async (date) => {
  return await handleAPIRequest(
    axiosInstance.get,
    date ? `api/employee_leaves?filter_date=${date}` : `api/employee_leaves`
  );
};

export const salaryslipApi = async (id, data) => {
  return await handleAPIRequest(
    axiosInstance.patch,
    `api/salary_slip/${id}/`,
    data
  );
};

export const singlesalrayApi = async (id) => {
  return await handleAPIRequest(axiosInstance.get, `api/single_salary/${id}`);
};

export const singleEmployeeAttandenceApi = async (date) => {
  return await handleAPIRequest(axiosInstance.get, `api/SinglePresentEmployee?date=${date}`);
};

export const singleDepartmentApi = async () => {
  return await handleAPIRequest(axiosInstance.get, `api/departmentList/`);
};
