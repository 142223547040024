import React, { useEffect, useState } from "react";
import { Layout } from "components/Layout/DashboardLayout";
import Button from "components/Button";
import ShareGroceryDialog from "./Elements/ShareGroceryDialog.jsx";
import Svgs, { Arrows, Threedots } from "assets/svgs/Index.js";
import Moreoptions from "./Elements/Moreoptions.jsx";
import SelectDropdown from "components/SelectDropdown.jsx";
import PayrollDropdown from "components/PayrollDropdown.jsx";
import { PayrollData } from "Pages/HRdata/Payroll/PayrollData.jsx";
import { salaryListApi } from "api/taskManagement.js";

function Payroll() {
  const [addsalaryPopup, setSalaryPopup] = useState(false);
  const [payrollthreedotsPopups, setPayrollPopups] = useState(false);
  const [payrollindex, setPayrollindex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  const handleShowsalaryPopup = () => {
    setSalaryPopup(true);
  };

  const handleClosesalaryPopup = () => {
    setSalaryPopup(false);
  };

  useEffect(() => {
    if (addsalaryPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [addsalaryPopup]);

  const handleShowpayrollPopups = (index) => {
    setPayrollPopups(true);
    setPayrollindex(index);
  };

  const handleClosepayrollPopups = () => {
    setPayrollPopups(false);
    setPayrollindex(null);
  };

  const roleOptions = [
    { value: "designation", label: "Designation" },
    { value: "Web developer", label: "Web developer" },
    { value: "python developer", label: "Python developer" },
    { value: "ui ux designer", label: "UI UX designer" },
    { value: "digital marketing", label: "Digital marketing" },
  ];

  // const payrollOptions = [
  //   { value: "In progress", label: "In progress" },
  //   { value: "Pending", label: "Pending" },
  //   { value: "Paid", label: "Paid" },
  // ];

  const payrollOptions = [
    { value: "in progress", label: "In Progress" },
    { value: "pending", label: "Pending" },
    { value: "paid", label: "Paid" },
  ];

  // SALARY_STATUS_CHOICES = [
  //   ("in progess", "In Progress"),
  //   ("pending", "Pending"),
  //   ("paid", "Paid"),
  // ];

  const [salaryList, setsalaryList] = useState();

  console.log("salaryList=======>", salaryList);

  // Calculate the total number of pages
  const totalPages = Math.ceil(salaryList?.length / itemsPerPage);

  // Get the current page's data
  const currentData = salaryList?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const maxPaginationButtons = 5; // Maximum number of pagination buttons to show

  // Calculate the range of pages to display
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxPaginationButtons / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxPaginationButtons - 1);

  // Adjust the startPage if the endPage exceeds the totalPages
  const adjustedStartPage = Math.max(1, endPage - maxPaginationButtons + 1);

  useEffect(() => {
    const takedata = async () => {
      try {
        const response = await salaryListApi();
        console.log("pendingLeaves  244==>>>", response);
        setsalaryList(response.results); // Store the response data in state
      } catch (error) {
        console.log("error fetching data:", error);
      }
    };
    takedata();
  }, []);

  return (
    <Layout active={"Payroll"}>
      <div className="min-h-screen">
        <div className="flex flex-wrap gap-[10px] sm:gap-[0px] sm:flex-row justify-between sm:items-center sm:space-y-0">
          <h2 className="lg:text-2xl sm:text-lg text-base font-bold font-public-sans text-gray-1 ">
            Payroll
          </h2>
          <div className="flex sm:space-x-4 flex-col sm:flex-row items-center space-y-4 sm:space-y-0 ">
            <div className="md:text-sm text-xs font-public-sans font-semibold text-gray-1">
              <SelectDropdown
                label=""
                options={roleOptions}
                name="role"
                id="role"
                selectClass={"w-full"}
                className={"w-[185px] border-gray-1"}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="container min-w-full ">
            {currentData?.length === 0 ? (
              <p className="w-full text-center py-5 bg-white rounded-[12px] text-black text-base mt-5">
                No data found
              </p>
            ) : (
              <div className="overflow-auto min-w-full">
                <div className="flex gap-4 flex-col min-w-[950px]">
                  <div className="mt-[24px] min-w-[900px]">
                    <div className="flex font-bold bg-[#f3f3f3] border rounded-lg justify-between w-[100%] py-5">
                      <div className="flex items-center gap-[9px] text-sm text-gray-1 font-public-sans px-[46px] font-normal">
                        <h1>Name</h1>
                        <span>
                          <Arrows />
                        </span>
                      </div>
                      <h1 className="p-2 text-gray-1 font-medium text-sm font-public-sans">
                        Position
                      </h1>
                      <div className="flex items-center gap-[9px] text-sm text-gray-1 font-public-sans font-normal">
                        <h1>Rate</h1>
                        <span>
                          <Arrows />
                        </span>
                      </div>
                      <h1 className="text-gray-1 font-public-sans text-sm p-2 font-medium">
                        Period
                      </h1>
                      <h1 className="text-gray-1 font-public-sans text-sm p-2 font-medium">
                        Status
                      </h1>
                      <h1 className="text-gray-1 font-public-sans text-sm p-2 px-[20px] font-medium">
                        Action
                      </h1>
                    </div>
                  </div>
                  <div className="mt-[20px] overflow-y-auto h-[560px]">
                    {currentData?.map((payroll, index) => (
                      <div
                        key={index}
                        className={`flex justify-between w-[100%] items-center border-b hover:bg-gray-100`}
                      >
                        <div className="py-[10px] mx-[16px] flex items-center w-full">
                          <img
                            src={payroll?.upload_image}
                            alt="profile"
                            className="w-[42px] h-[42px] rounded-full mr-3"
                          />
                          <div>
                            <p className="lg:text-sm text-xs font-public-sans text-gray-1 font-medium">
                              {payroll?.user?.full_name}
                            </p>
                            <p className="lg:text-xs text-[10px] text-[#00B037] font-public-sans">
                              {payroll?.user?.email}
                            </p>
                          </div>
                        </div>

                        <div className="p-2 lg:w-4/5 w-full lg:min-w-[186px]">
                          <span
                            className={`ml-6 px-[12px] py-[6px] lg:text-sm text-[10px] font-public-sans font-medium rounded-full ${payroll.positionColor}`}
                          >
                            {payroll?.department}
                          </span>
                        </div>
                        <div className="flex flex-col text-center w-11/12 min-w-[146px] justify-start items-start ml-16">
                          <p className="mr-6 lg:text-xs text-[10px] font-public-sans text-gray-4">
                            {payroll?.pay_type}
                          </p>
                          <p className="mr-6 lg:text-sm font-medium text-xs text-gray-1 font-public-sans">
                            {payroll?.salary}
                          </p>
                        </div>
                        <div className="w-11/12 min-w-[156px]">
                          <p className="lg:text-xs text-[10px] font-public-sans text-gray-4">
                            {payroll?.created_at}
                          </p>
                          <p className="lg:text-sm font-medium text-xs text-gray-1 font-public-sans">
                            {payroll?.period}
                          </p>
                        </div>
                        <div className=""></div>
                        <div className="lg:p-2 font-medium font-public-sans lg:text-sm text-xs w-11/12 min-w-[156px] mr-12">
                          <span className={`w-[110px]`}>
                            <PayrollDropdown
                              rowid={payroll.id}
                              label=""
                              options={payrollOptions}
                              name="role"
                              id="role"
                              className=""
                              customWidth="w-[150px]"
                              customHeight="h-[40px]"
                              customPadding="px-2 py-1"
                              salarayStatus={payroll?.salary_status}
                            />
                          </span>
                        </div>

                        <div className='className="px-[20px] p-2 text-center items-center justify-center mr-4 w-10/12"'>
                          <button
                            onClick={() => {
                              handleShowpayrollPopups(index);
                            }}
                            className="text-gray-500 hover:text-gray-700"
                          >
                            <Threedots />
                          </button>
                          {payrollindex === index && payrollthreedotsPopups && (
                            <Moreoptions
                              show={payrollthreedotsPopups}
                              onClose={handleClosepayrollPopups}
                              setPayrollindex={setPayrollindex}
                              setPayrollPopups={setPayrollPopups}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-end mt-4 space-x-2">
                    {/* Previous Button */}
                    {currentPage > 1 && (
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="px-4 py-2 border rounded-md bg-white text-gray-700 hover:bg-gray-200"
                      >
                        &lt;
                      </button>
                    )}

                    {/* Pagination Buttons */}
                    {Array.from(
                      { length: endPage - adjustedStartPage + 1 },
                      (_, i) => adjustedStartPage + i
                    ).map((page) => (
                      <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`px-4 py-2 border rounded-md ${
                          page === currentPage
                            ? "bg-btn-gradient text-white"
                            : "bg-white text-gray-700 hover:bg-gray-200"
                        }`}
                      >
                        {page}
                      </button>
                    ))}

                    {/* Next Button */}
                    {currentPage < totalPages && (
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        className="px-4 py-2 border rounded-md bg-white text-gray-700 hover:bg-gray-200"
                      >
                        &gt;
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Payroll;
