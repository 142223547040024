import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelectDropdown from "components/SelectDropdown";

const ActiveStep2 = ({
  prevsStep,
  activeStep,
  handleNextClick,
  steps,
  setAllFormData,
  allFormData,
}) => {
  // Dropdown options
  const positionOptions = [
    { value: "JR", label: "Junior" },
    { value: "SR", label: "Senior" },
    { value: "MG", label: "Manager" },
    { value: "PM", label: "Project Manager" },
  ];

  // Formik setup
  const formik = useFormik({
    initialValues: {
      position: "",
      dateOfJoining: "",
      probationPeriod: "",
      basicSalary: "",
      houseAllowance: "",
      foodAllowance: "",
      transportAllowance: "",
    },
    validationSchema: Yup.object({
      position: Yup.string().required("Position is required"),
      dateOfJoining: Yup.string().required("Date of joining is required"),
      probationPeriod: Yup.string().required("Probation period is required"),
      basicSalary: Yup.string().required("Basic salary is required"),
      houseAllowance: Yup.string().required("House allowance is required"),
      foodAllowance: Yup.string().required("Food allowance is required"),
      transportAllowance: Yup.string().required(
        "Transport allowance is required"
      ),
    }),
    onSubmit: (values) => {
      console.log("Form Submitted with data:", values);
      setAllFormData((prev) => ({
        ...prev,
        ...values,
      })); // Log final form data
    },
  });

  return (
    <div className="max-h-[746px]">
      <form onSubmit={formik.handleSubmit}>
        {/* Dropdown for Position */}
        <div>
          <SelectDropdown
            label={"Position"}
            options={positionOptions}
            selectClass={"w-full"}
            className={"w-full"}
            customPadding={"px-4 py-[4.5px]"}
            customMargin={"mt-3"}
            onChange={(selectedOption) =>
              formik.setFieldValue("position", selectedOption.target.value)
            }
          />
          {formik.touched.position && formik.errors.position && (
            <p className="text-red-500 text-sm mt-1">
              {formik.errors.position}
            </p>
          )}
        </div>

        {/* Date of Joining */}
        <div className="mt-6">
          <label className="block text-[16px] font-mulish font-bold text-gray-1">
            Date of Joining
          </label>
          <input
            type="date"
            name="dateOfJoining"
            placeholder="30-Oct-2024"
            value={formik.values.dateOfJoining}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`text-gray-4 lg:text-base font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border ${
              formik.errors.dateOfJoining && formik.touched.dateOfJoining
                ? "border-red-500"
                : "border-gray-5"
            } rounded-lg shadow-sm text-sm`}
          />
          {formik.touched.dateOfJoining && formik.errors.dateOfJoining && (
            <p className="text-red-500 text-sm mt-1">
              {formik.errors.dateOfJoining}
            </p>
          )}
        </div>

        {/* Probation Period */}
        <div className="mt-6">
          <label className="block text-[16px] font-mulish font-bold text-gray-1">
            Probation Period
          </label>
          <input
            type="number" // Changed to number type
            name="probationPeriod"
            placeholder="3"
            value={formik.values.probationPeriod}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`text-gray-4 lg:text-base font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border ${
              formik.errors.probationPeriod && formik.touched.probationPeriod
                ? "border-red-500"
                : "border-gray-5"
            } rounded-lg shadow-sm text-sm`}
            min="0" // To ensure only non-negative numbers can be entered
          />
          {formik.touched.probationPeriod && formik.errors.probationPeriod && (
            <p className="text-red-500 text-sm mt-1">
              {formik.errors.probationPeriod}
            </p>
          )}
        </div>

        {/* Basic Salary */}
        {/* Basic Salary */}
        <div className="mt-6">
          <label className="block text-[16px] font-mulish font-bold text-gray-1">
            Basic Salary
          </label>
          <input
            type="number" // Changed from text to number
            name="basicSalary"
            placeholder="100"
            value={formik.values.basicSalary}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`text-gray-4 lg:text-base font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border ${
              formik.errors.basicSalary && formik.touched.basicSalary
                ? "border-red-500"
                : "border-gray-5"
            } rounded-lg shadow-sm text-sm`}
          />
          {formik.touched.basicSalary && formik.errors.basicSalary && (
            <p className="text-red-500 text-sm mt-1">
              {formik.errors.basicSalary}
            </p>
          )}
        </div>

        {/* Other Allowances */}
        {["houseAllowance", "foodAllowance", "transportAllowance"].map(
          (field, idx) => (
            <div key={idx} className="mt-6">
              <label className="block text-[16px] font-mulish font-bold text-gray-1">
                {field
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}{" "}
                {/* Format field */}
              </label>
              <input
                type="text"
                name={field}
                placeholder={field === "houseAllowance" ? "$20" : "$20"}
                value={formik.values[field]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`text-gray-4 lg:text-base font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border ${
                  formik.errors[field] && formik.touched[field]
                    ? "border-red-500"
                    : "border-gray-5"
                } rounded-lg shadow-sm text-sm`}
              />
              {formik.touched[field] && formik.errors[field] && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors[field]}
                </p>
              )}
            </div>
          )
        )}

        {/* Buttons */}
        <div className="flex justify-center mt-[32px] gap-[10px] pb-[32px]">
          {/* Back Button */}
          <button
            type="button"
            onClick={prevsStep}
            disabled={activeStep === 0}
            className={`${
              activeStep === 0 ? "hidden" : ""
            } w-[162px] py-[14px] px-[24px] font-mulish 
            border rounded-lg border-[#f65154] font-bold lg:text-base text-sm text-transparent bg-clip-text bg-btn-gradient`}
          >
            Back
          </button>

          {/* Next Button */}
          <button
            type="button"
            onClick={async () => {
              formik.setTouched({
                position: true,
                dateOfJoining: true,
                probationPeriod: true,
                basicSalary: true,
                houseAllowance: true,
                foodAllowance: true,
                transportAllowance: true,
              });

              const errors = await formik.validateForm();

              if (Object.keys(errors).length === 0) {
                setAllFormData((prev) => ({
                  ...prev,
                  ...formik.values,
                }));

                if (activeStep === 5) {
                  formik.submitForm();
                } else {
                  handleNextClick();
                }
              }
            }}
            className="lg:text-base w-[162px] text-sm font-mulish font-bold 
            py-[14px] px-[24px] bg-btn-gradient text-white rounded-lg
            shadow-sm hover:bg-red-600 focus:outline-none"
          >
            {activeStep === 5 ? "Submit" : "Next"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ActiveStep2;
