import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MyTaskCard from "./Pages/UserDashboard/MyTasks/MyTaskCard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyTeam from "./Pages/UserDashboard/MYTeam";
import RequireAuth from "./components/RequireAuth";
import { Dashboard } from "./Pages/UserDashboard/Dashboard";
import Tasks from "./Pages/UserDashboard/MyTasks";
import AttendanceAndLeaveUser from "./Pages/UserDashboard/AttendanceAndLeave";
import MyProfileUser from "./Pages/UserDashboard/MyProfile";
import Applicants from "./Pages/HRDashboard/Applicants";
import Payroll from "./Pages/HRDashboard/Payroll";
import Chat from "./Pages/UserDashboard/Chat";
import DocumentsHR from "./Pages/HRDashboard/Documents";
import AttendanceAndLeaveHR from "./Pages/HRDashboard/AttandenceAndLeave";
import FindColleagues from "./Pages/UserDashboard/Chat/Elements/FindColleagues";
import Employeesdetail from "./Pages/HRDashboard/Employees/Employeesdetail";
import TeamMembers from "./Pages/UserDashboard/MYTeam/TeamMembers";
import { SignUpPage } from "./Pages/Auth/SignUp/Index";
import { LoginPage } from "./Pages/Auth/LogIn/Index";
import ChatProfileuser from "Pages/UserDashboard/Chat/ChatProfile";
import ChatProfile from "Pages/HRDashboard/Chat/ChatProfile";
import DashboardHR from "./Pages/HRDashboard/Dashboard/index";
import HRChat from "./Pages/HRDashboard/Chat";
import EmployeesHR from "./Pages/HRDashboard/Employees";
import MyProfileeHR from "./Pages/HRDashboard/Myprofile";

function App() {
  // const { user } = useUserContext();
  const router = createBrowserRouter([
    { path: "/", element: <LoginPage /> },
    { path: "/sign-up", element: <SignUpPage /> },

    {
      path: "/user",
      element: <RequireAuth allowedRoles={"employee"} />,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "tasks",
          element: <Tasks />,
        },
        {
          path: "chat",
          element: <Chat />,
        },
        {
          path: "my-team",
          element: <MyTeam />,
        },
        {
          path: "my-profile",
          element: <MyProfileUser />,
        },
        {
          path: "my-task-card",
          element: <MyTaskCard />,
        },
        {
          path: "attendance-leave",
          element: <AttendanceAndLeaveUser />,
        },
        {
          path: "chatfindcolleagues",
          element: <FindColleagues />,
        },
        {
          path: "my-teammembers",
          element: <TeamMembers />,
        },
        {
          path: "ChatProfileuser",
          element: <ChatProfileuser />,
        },
        {
          path: "chat-profile",
          element: <ChatProfile />,
        },
      ],
    },

    {
      path: "/hr",
      element: <RequireAuth allowedRoles={"hr"} />,
      children: [
        {
          path: "dashboard",
          element: <DashboardHR />,
        },
        {
          path: "attandence",
          element: <AttendanceAndLeaveHR />,
        },
        {
          path: "chat",
          element: <HRChat />,
        },
        {
          path: "payroll",
          element: <Payroll />,
        },
        {
          path: "documents",
          element: <DocumentsHR />,
        },
        {
          path: "applicants",
          element: <Applicants />,
        },
        {
          path: "employees",
          element: <EmployeesHR />,
        },
        {
          path: "myprofile",
          element: <MyProfileeHR />,
        },

        // {
        //   path: "employeesdetails",
        //   element: <Employeesdetail />,
        // },

        {
          path: "employeesdetails/:id",
          element: <Employeesdetail />,
        },
        {
          path: "chat-profile",
          element: <ChatProfile />,
        },
      ],
    },
    // { path: "*", element: <Navigate to="/" replace /> }
  ]);

  // useEffect(() => {
  //   // const token = localStorage.getItem("token");
  //   if (!user) {
  //     window.location.href = "/";
  //   }
  // }, []);

  return (
    <div className="wrapper">
      <ToastContainer />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
