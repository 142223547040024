import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../../../src/components/Input";
import Button from "../../../components/Button";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useUserContext } from "../../../Context/UserProvider";
import { signinApi } from "api/taskManagement";
import { toast } from "react-toastify";

// Validation schema
const signInSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Please enter your email"),
  password: yup.string().required("Please enter your password"),
});

const initialValues = {
  email: "",
  password: "",
};

export const LoginPage = () => {
  const navigate = useNavigate();
  const { user, setUser, logoutUser } = useUserContext();

  const handleFormSubmit = async (values) => {
    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("password", values.password);

    try {
      const response = await signinApi(formData);

      if (response.success) {
        const { data, token, message } = response.response;
        const { office_end_time, office_start_time, employee_type } = data;

        const now = new Date();
        let expiryTime;

        if (employee_type === "onsite") {
          // Onsite: Expiry is based on office_end_time
          const [hours, minutes, seconds] = office_end_time
            .split(":")
            .map(Number);

          expiryTime = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            hours,
            minutes,
            seconds
          );

          // Adjust if the office_end_time is for the next day
          if (expiryTime < now) {
            expiryTime.setDate(expiryTime.getDate() + 1);
          }
        } else {
          // Remote: Expiry is 9 hours after office_start_time
          const [hours, minutes, seconds] = office_start_time
            .split(":")
            .map(Number);

          const checkInTime = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            hours,
            minutes,
            seconds
          );

          // Add 9 hours to office_start_time for expiry
          expiryTime = new Date(checkInTime.getTime() + 9 * 60 * 60 * 1000);

          // If the current time is already after the check-in time + 9 hours, set expiry to the next day
          if (expiryTime < now) {
            expiryTime.setDate(expiryTime.getDate() + 1);
          }
        }

        const expiryTimestamp = expiryTime.getTime();

        // Save expiry time and token in localStorage
        localStorage.setItem("tokenExpiry", expiryTimestamp);
        localStorage.setItem("token", token);
        localStorage.setItem("userId", data.id);

        // Update User Context
        setUser({
          isLoggedIn: true,
          role: data.role,
          token,
          tokenExpiry: expiryTimestamp,
        });

        // Schedule auto logout
        const timeUntilExpiry = expiryTimestamp - now.getTime();
        setTimeout(() => {
          logoutUser();
          toast.info("Session expired. Please log in again.", {
            autoClose: 3000,
          });
          navigate("/login");
        }, timeUntilExpiry);

        toast.success(message, { autoClose: 3000 });
        navigate(data.role === "hr" ? "/hr/dashboard" : "/user/dashboard");
      } else {
        toast.error(response?.response?.message || "Login failed!", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.response?.message || "Login failed!", {
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const tokenExpiry = localStorage.getItem("tokenExpiry");

    if (token && tokenExpiry) {
      const now = new Date().getTime();
      if (now >= tokenExpiry) {
        logoutUser(); // Log the user out if the token is expired
      }
    }

    // Redirect based on role if token is still valid
    if (token && user.role === "hr") {
      navigate("/hr/dashboard");
    } else if (token && user.role === "employee") {
      navigate("/user/dashboard");
    }
  }, [user.role, navigate, logoutUser]);

  return (
    <div className="grid grid-cols-12 h-screen">
      {/* Left Side */}
      <div className="col-span-12 lg:col-span-6 xl:col-span-7 bg-gray-100 space-y-8 lg:space-y-16 p-8 flex flex-col justify-center items-center relative">
        <div className="flex items-center justify-start flex-col gap-5 relative lg:absolute top-4">
          <h1 className="text-2xl lg:text-[40px] py-2 font-bold font-mulish text-gray-1">
            Dashboard
          </h1>
          <p className="text-sm lg:text-base font-normal font-mulish text-gray-1 text-center">
            Manage your project and team in an easy way
          </p>
        </div>
        <img src={"/images/LogoMR1.png"} alt="logo" className="w-3/5" />
      </div>

      {/* Right Side */}
      <div className="col-span-12 lg:col-span-6 xl:col-span-5 flex flex-col items-center justify-center lg:px-20 py-8 lg:py-8 bg-white">
        <div className="flex items-center justify-center flex-col sm:gap-[64px] gap-[32px]">
          <h1 className="text-2xl lg:text-[31px] font-mulish text-gray-1 font-bold text-center">
            Sign in to Dashboard
          </h1>

          <Formik
            initialValues={initialValues}
            validationSchema={signInSchema}
            validateOnChange={true}
            onSubmit={handleFormSubmit}
          >
            {({
              handleSubmit,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className="w-full flex flex-col gap-[20px]"
              >
                <Input
                  id="email"
                  type="email"
                  label="Email"
                  placeholder="Enter your email"
                  className="w-full sm:w-[350px]"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && errors.email}
                />
                {touched.email && errors.email && (
                  <small className="text-custom-red">{errors.email}</small>
                )}
                <Input
                  id="password"
                  type="password"
                  label="Password"
                  placeholder="Enter your password"
                  className="w-full sm:w-[350px]"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && errors.password}
                />
                {touched.password && errors.password && (
                  <small className="text-custom-red">{errors.password}</small>
                )}
                <Button
                  type="submit"
                  text="Sign in"
                  className="w-full"
                  customPadding="px-[24px] py-[14px]"
                />
              </Form>
            )}
          </Formik>
          <div className="flex items-center gap-1">
            <h1 className="text-sm lg:text-base font-mulish font-normal text-gray-4 text-center">
              Doesn’t have an account?&nbsp;
            </h1>
            <Link
              to="/sign-up"
              className="bg-btn-gradient text-transparent bg-clip-text text-sm lg:text-base font-bold font-mulish"
            >
              Sign up Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
