import React, { useEffect, useRef } from "react";

function DocumentModal({ show, onClose ,userdata ,selectedDocument }) {
  console.log('selectedDocument',selectedDocument)
  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        ref={popupRef}
        className="bg-white shadow-lg rounded-lg border border-gray-300 p-6 mx-4 w-full max-w-[700px] relative"
      >
        {/* Decorative Border */}
        <div className="absolute inset-0 border-4 border-dashed border-gray-400 rounded-lg pointer-events-none"></div>

        {/* Certificate Content */}
          <img src={userdata?.offer_letter} alt="" />
      </div>
    </div>
  );
}

export default DocumentModal;
