import React from "react";
import { useState, useEffect, useRef } from "react";
import Addnewemployee from "./Addnewemployee";

function ShareGroceryDialog({ show, onClose, setAddEmployee, takedata }) {
  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 rounded-md">
      <div
        ref={popupRef}
        className="  scroll-smooth w-[95vw]  md:w-[598px] h-[75%]  grocery-scrollbar-none overflow-y-scroll rounded-md "
      >
        <Addnewemployee
          takedata={takedata}
          onClose={onClose}
          setAddEmployee={setAddEmployee}
          showModal={show}
        />
      </div>
    </div>
  );
}

export default ShareGroceryDialog;
