export const attendance = [
    {
        name: "Anna",
        role: "Python developer",
        status: "Present",
        time: "10:00AM",
        img: "/images/(anna).png",
        working: "On-Site"
      },
      {
        name: "Catherine",
        role: "Web designer",
        status: "Present",
        time: "10:00AM",
        img: "/images/(catherine5).png",
        working: "Remote"
      },
      {
        name: "Orton",
        role: "UI UX designer",
        status: "Present",
        time: "10:00AM",
        img: "/images/(ortan6).png",
        working: "Hybrid"
      },
      {
        name: "Jessy",
        role: "Web developer",
        status: "Present",
        time: "10:00AM",
        img: "/images/(jessy7).png",
        working: "Flexible"
      },
      {
        name: "Orton",
        role: "UI UX designer",
        status: "Present",
        time: "10:00AM",
        img: "/images/(ortan6).png",
        working: "Other"
      },
  
      {
        name: "Orton",
        role: "UI UX designer",
        status: "Present",
        time: "10:00AM",
        img: "/images/(ortan6).png",
        working: "Status"
      },
  
]