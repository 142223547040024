export const TimeFormatterAttendence = (checkInTime) => {
    if (!checkInTime) return "Invalid time";
  
    const [hours, minutes] = checkInTime.split(":").map(Number);
  
    if (hours === undefined || minutes === undefined || isNaN(hours) || isNaN(minutes)) {
      return "Invalid time";
    }
  
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
  
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };
  