import React, { useState } from "react";
import Svgs, { Crossiconssvg } from "../../../../assets/svgs/Index";
import RoundedCard from "components/Elements/RoundedCard";

const Skills = ({ userdata }) => {
  console.log("userdata", userdata);
  const [isEditing, setIsEditing] = useState(false);
  const [skills, setSkills] = useState([
    
  ]);

  const [editableSkill, setEditableSkill] = useState(null);
  const [tempSkill, setTempSkill] = useState("");

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleEditSkill = (index, value) => {
    setEditableSkill(index);
    setTempSkill(value);
  };

  const handleSaveSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills[index] = tempSkill.trim();
    setSkills(updatedSkills);
    setEditableSkill(null);
    setTempSkill("");
  };

  const handleDeleteSkill = (index) => {
    setSkills(skills.filter((_, i) => i !== index));
  };

  return (
    <div>
      <RoundedCard>
        <div className="flex items-center justify-between">
          <h1 className="text-xl font-semibold font-public-sans text-gray-1">
            Skills
          </h1>
          <div onClick={toggleEditing} className="cursor-pointer">
            {isEditing ? (
              <span className="text-red-500 font-semibold">Save</span>
            ) : (
              <Svgs.Editicon />
            )}
          </div>
        </div>
        <div className="flex items-center justify-start flex-wrap mt-4 gap-3">
          {userdata?.add_skills?.map((skill, index) => (
            <div
              key={index}
              className="rounded-full border flex items-center gap-2 px-4 py-2 relative"
            >
              {editableSkill === index ? (
                <input
                  type="text"
                  value={tempSkill}
                  onChange={(e) => setTempSkill(e.target.value)}
                  onBlur={() => handleSaveSkill(index)}
                  className="text-sm font-public-sans text-gray-1 outline-none  "
                />
              ) : (
                <span
                  onClick={() => isEditing && handleEditSkill(index, skill)}
                  className={`text-sm font-public-sans text-gray-1 ${
                    isEditing ? "cursor-pointer" : ""
                  }`}
                >
                  {skill?.name}
                </span>
              )}
              {isEditing && (
                <butt
                  on
                  onClick={() => handleDeleteSkill(index)}
                  className="text-gray-500 hover:text-gray-700  absolute -right-1 -top-2"
                >
                  <Crossiconssvg />
                </butt>
              )}
            </div>
          ))}
        </div>
      </RoundedCard>
    </div>
  );
};

export default Skills;
