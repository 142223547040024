import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SelectDropdown from "components/SelectDropdown";
import DatePicker from "react-datepicker";

const identityProofOptions = [
  { value: "Passport", label: "Passport" },
  { value: "Driving License", label: "Driving License" },
  { value: "Aadhaar", label: "Aadhaar" },
];

const maritalStatusOptions = [
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
];

const validationSchema = Yup.object().shape({
  identityProof: Yup.string().required("Identity proof is required"),
  uniqueIdNumber: Yup.string()
    .required("Unique ID number is required")
    .matches(/^\d+$/, "Unique ID must be a number"),
  dateOfBirth: Yup.date().required("Date of birth is required").nullable(),
  maritalStatus: Yup.string().required("Marital status is required"),
  image: Yup.mixed().required("Image is required"),
});

const ActiveStep1 = ({
  prevsStep,
  activeStep,
  handleNextClick,
  steps,
  setAllFormData,
  allFormData,
}) => {
  const [image, setImage] = useState(null);

  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setFieldValue("image", file);
    } else {
      setFieldValue("image", null);
    }
  };

  return (
    <Formik
      initialValues={{
        identityProof: "",
        uniqueIdNumber: "",
        dateOfBirth: null,
        maritalStatus: "",
        image: null,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const formattedValues = {
          ...values,
          dateOfBirth: values.dateOfBirth
            ? values.dateOfBirth.toISOString().split("T")[0]
            : null, // Format date to "YYYY-MM-DD"
        };

        console.log("Form Data: ", formattedValues);
        setAllFormData((prev) => ({
          ...prev,
          ...formattedValues,
        }));
        handleNextClick();
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <div className="px-6 max-h-[752px]">
            <div className="flex items-center justify-center border w-full rounded-lg p-4 border-gray-5">
              <div className="flex justify-center items-center flex-col">
                <label htmlFor="upload-button" className="cursor-pointer">
                  <div className="border-[#f6f6f6] bg-[#f6f6f6] rounded-full flex items-center justify-center px-[18px] py-[40px]">
                    <button className="rounded-lg border border-gray-2 py-[7px] px-[12.5px] text-gray-2 font-public-sans font-semibold text-sm">
                      Browse
                    </button>
                  </div>
                </label>
                <input
                  id="upload-button"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, setFieldValue)}
                  className="hidden"
                />
                {image && (
                  <img
                    src={image}
                    alt="Uploaded"
                    className="w-full h-full object-cover border rounded-lg mt-4"
                  />
                )}
                <ErrorMessage
                  name="image"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            <div className="sm:flex gap-[10px] mt-[24px]">
              <div className="w-full">
                <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
                  Identity proof
                </label>
                <Field
                  name="identityProof"
                  as={SelectDropdown}
                  options={identityProofOptions}
                  className="w-full"
                />
                <ErrorMessage
                  name="identityProof"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="w-full">
                <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
                  Unique ID number
                </label>
                <Field
                  name="uniqueIdNumber"
                  type="text"
                  placeholder="Enter Unique ID Number"
                  className="lg:text-base text-sm font-mulish mt-[8px] block w-full px-[16px] py-[13.5px] border border-gray-5 rounded-lg shadow-sm sm:text-sm"
                />
                <ErrorMessage
                  name="uniqueIdNumber"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            <div className="mb-4 mt-4">
              <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
                Date of birth
              </label>
              <DatePicker
                selected={values.dateOfBirth}
                onChange={(date) => setFieldValue("dateOfBirth", date)}
                placeholderText="Select your date"
                className="form-control lg:text-base text-sm font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm sm:text-sm pr-10"
              />
              <ErrorMessage
                name="dateOfBirth"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4">
              <label className="lg:text-base text-sm font-bold text-gray-1 font-mulish">
                Marital status
              </label>
              <Field
                name="maritalStatus"
                as={SelectDropdown}
                options={maritalStatusOptions}
                className="w-full"
              />
              <ErrorMessage
                name="maritalStatus"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="flex justify-center mt-[32px] gap-[10px]">
              <button
                type="button"
                onClick={prevsStep}
                disabled={activeStep === 0}
                className={`${
                  activeStep === 0 ? "hidden" : ""
                } w-[162px] py-[14px] px-[24px] font-mulish 
                border rounded-lg border-[#f65154] font-bold lg:text-base text-sm text-transparent bg-clip-text bg-btn-gradient`}
              >
                Back
              </button>

              <button
                type="submit"
                className="lg:text-base w-[162px] text-sm font-mulish font-bold py-[14px] px-[24px] bg-btn-gradient text-white rounded-lg
              shadow-sm hover:bg-red-600 focus:outline-none"
              >
                {activeStep === 5 ? "Submit" : "Next"}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ActiveStep1;
