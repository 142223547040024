import React, { useEffect } from "react";
import { Layout } from "components/Layout/DashboardLayout";
import { useState } from "react";
import Button from "components/Button";
import ApplyLeaveDialog from "../../HRDashboard/AttandenceAndLeave/ApplyLeave";
import Calendar from "../../HRDashboard/AttandenceAndLeave/Calendar";
import RoundedCard from "../../../components/Elements/RoundedCard";
import { Blueline, Greenline, Plus, Plusfour } from "assets/svgs/Index";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import ReviweLeaveDialog from "Pages/UserDashboard/AttendanceAndLeave/Element/ReviewLeaveDialog";
import FestivalLeaveDialog from "./FestivalLeave";
import OfficeTimeDialog from "./OfficeTimeModal";
import { attandence_activity } from "Pages/HRdata/Attanadance/AttandenceActivity";
import {
  attendanceSummaryApi,
  pendingleaveslistApi,
  presentEmployeeslistApi,
  singleEmployeeAttandenceApi,
} from "api/taskManagement";
import { TimeFormatterAttendence } from "utils/timeFormatterAttendence";

function AttendanceAndLeaveHR() {
  const [showFestivalLeave, setShowFestivalLeave] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [popupindex, setPopupindex] = useState(null);
  const [showEditLoginTimeModal, setShowEditLoginTimeModal] = useState(false);
  const [reviewLeaveDialog, setReviewLeaveDialog] = useState(false);
  const [officeTime, setOfficeTime] = useState(false);

  const handleShowPopup = (index) => {
    setShowEditLoginTimeModal(true);
    setPopupindex(index);
  };

  const handleClosePopup = (index) => {
    setShowEditLoginTimeModal(false);
    setPopupindex(index);
  };

  const [showApplyLeave, setShowApplyLeave] = useState(false);

  const handleShowApplyLeave = (index) => {
    setShowApplyLeave(true);
  };

  const handleCloseApplyLeave = (index) => {
    setShowApplyLeave(false);
  };
  const attendanceData = {
    attendancePercentage: 98,
    present: 24,
    onLeave: 0,
    absent: 1,
  };
  const circleRadius = 50;
  const circleCircumference = 2 * Math.PI * circleRadius;
  const progress =
    (attendanceData.attendancePercentage / 100) * circleCircumference;

  const [presentEmployees, setPresentEmployees] = useState();

  console.log("attandencetime", presentEmployees);

  useEffect(() => {
    const takedata = async () => {
      try {
        const response = await presentEmployeeslistApi();
        console.log("presentEmloyee==>>>", response);
        setPresentEmployees(response.response.present_employees); // Store the response data in state
      } catch (error) {
        console.log("error fetching data:", error);
      }
    };
    takedata();
  }, []);
  // prop sate of child calender
  const [selectedDate, setSelectedDate] = useState("");

  const [reviewData, setReviewData] = useState(null);
  const [attendancesummary, setAttendancesummary] = useState();

  console.log("reviewData", reviewData);

  console.log("attendancesummary=======>", attendancesummary);

  useEffect(() => {
    const takedata = async () => {
      try {
        const response = await attendanceSummaryApi(selectedDate);
        console.log("attendancesummary==>>>", response);
        setAttendancesummary(response.response); // Store the response data in state
      } catch (error) {
        console.log("error fetching data:", error);
      }
    };
    takedata();
  }, [selectedDate]);

  const [pendingLeaves, setPendingLeaves] = useState();

  console.log("PendingLeaves=======>", pendingLeaves);

  const takedata = async () => {
    try {
      const response = await pendingleaveslistApi();
      console.log("pendingLeaves  244==>>>", response);
      setPendingLeaves(response.response.data); // Store the response data in state
    } catch (error) {
      console.log("error fetching data:", error);
    }
  };

  useEffect(() => {
    takedata();
  }, []);

  const [presentSingleEmployees, setPresentSingleEmployees] = useState();

  console.log("presentSingleEmployees", presentSingleEmployees);

  useEffect(() => {
    const takedata = async () => {
      try {
        const response = await singleEmployeeAttandenceApi(selectedDate);
        console.log("presentSingleEmloyee==>>>", response);
        setPresentSingleEmployees(response.response.attendance); // Store the response data in state
      } catch (error) {
        console.log("error fetching data:", error);
      }
    };
    takedata();
  }, [selectedDate]);

  return (
    <Layout active={"Attendance & Leave"}>
      <div className="my-4 md:my-0">
        <div className="flex flex-wrap items-center justify-center sm:justify-between gap-3">
          <div className="flex flex-col gap-4">
            <div>
              <h1 className=" text-2xl font-bold font-public-sans text-gray-1">
                Team member
              </h1>
            </div>
            <div className="flex items-center">
              <img src="/images/member1.png" alt="" />
              <img src="/images/member2.png" width={"32px"} alt="" />
              <img src="/images/member3 (2).png" width={"32px"} alt="" />
              <img src="/images/member4 (2).png" width={"32px"} alt="" />
              <span className="w-[32px] h-[32px] flex items-center justify-center bg-[#f3f3f3] rounded-full">
                <Plusfour />
              </span>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center md:justify-start gap-5 my-3 md:my-0">
            <Button
              handleClick={handleShowApplyLeave}
              className={
                " text-sm font-semibold font-public-sans text-white flex items-center gap-3"
              }
              customPadding={"px-3 py-2"}
              text={
                <>
                  <Plus /> Apply leave
                </>
              }
            />

            <Button
              handleClick={() => setShowFestivalLeave(true)}
              className={
                " text-sm font-semibold font-public-sans text-white flex items-center gap-3"
              }
              customPadding={"px-3 py-2"}
              text={
                <>
                  <Plus /> Festival leave
                </>
              }
            />

            <Button
              handleClick={() => setOfficeTime(true)}
              className={
                " text-sm font-semibold font-public-sans text-white flex items-center gap-3"
              }
              customPadding={"px-3 py-2"}
              text={
                <>
                  <Plus /> Office Time
                </>
              }
            />
            {showApplyLeave && (
              <ApplyLeaveDialog
                show={showApplyLeave}
                onClose={handleCloseApplyLeave}
                takedata={takedata}
              />
            )}
            {showFestivalLeave && (
              <FestivalLeaveDialog
                show={showFestivalLeave}
                onClose={() => setShowFestivalLeave(false)}
              />
            )}
            {officeTime && (
              <OfficeTimeDialog
                show={officeTime}
                onClose={() => setOfficeTime(false)}
              />
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-5 my-8">
        <div className=" col-span-12 lg:col-span-8 ">
          <RoundedCard
            customPadding={""}
            className={"px-[24px] overflow-x-auto"}
          >
            <div className="min-w-[500px] ">
              {/* e here leave attdenc */}
              <div className="">
                <h3 className=" text-gray-1 text-base font-public-sans font-semibold">
                  Employees Attendance
                </h3>
              </div>
              <div className="py-6">
                <div className="flex  sm:gap-[23px] gap-[10px] items-center p-4 bg-[#f6f6f6] rounded-lg    ">
                  <div className="relative">
                    <div className="w-[140px] h-[140px]">
                      <CircularProgressbar
                        value={attendancesummary?.attendance_percentage}
                        strokeWidth="12"
                        styles={buildStyles({
                          pathColor: "#00b037",
                          textColor: "#000",
                        })}
                      />
                    </div>
                    <div className="absolute inset-0 flex items-center justify-center flex-col ">
                      <span className="text-[30px] font-bold text-[#333333] font-public-sans">
                        {attendancesummary?.attendance_percentage}%
                      </span>
                      <p className="text-gray-4 font-public-sans text-[12px] ">
                        attendance
                      </p>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex justify-between items-center  border-[#E0E0E0] border-b ">
                      <h6 className="font-public-sans text-[12px] text-[#4F4F4F] py-[8px] ">
                        Present
                      </h6>{" "}
                      <span>
                        <h6 className="font-public-sans text-[14px] text-[#333333]">
                          {attendancesummary?.total_present}
                        </h6>
                      </span>
                    </div>
                    <div className="flex justify-between items-center border-[#E0E0E0] border-b">
                      <h6 className="font-public-sans text-[12px] text-[#4F4F4F] py-[8px]">
                        On leave
                      </h6>{" "}
                      <span>
                        <h6 className=" font-public-sans text-[14px] text-[#333333]">
                          {attendancesummary?.total_on_leave}
                        </h6>
                      </span>
                    </div>
                    <div className="flex justify-between items-center  ">
                      <h6 className="font-public-sans text-[12px] text-[#4F4F4F] py-[8px] ">
                        Absent
                      </h6>{" "}
                      <span>
                        <h6 className="font-public-sans text-[14px] text-[#333333]">
                          {attendancesummary?.total_absent}
                        </h6>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="overflow-x-auto max-h-[390px] h-fit">
                <div className="min-w-max">
                  <table className="w-full text-sm text-left rtl:text-right min-w-max">
                    <tbody>
                      {presentEmployees?.map((value, index) => (
                        <tr key={index} className="border-b border-[#EEEEEE]">
                          <td className="pr- py-3">
                            <div className="flex items-center gap-3">
                              <img
                                className="w-[42px] h-[42px]"
                                src={value?.upload_image}
                                alt="Employee"
                              />
                              <span className="text-sm font-semibold text-gray-1 font-public-sans">
                                {value?.full_name}
                              </span>
                            </div>
                          </td>
                          <td className=" py-3 ">
                            <span className="text-sm font-normal text-gray-2 font-public-sans">
                              {{
                                JR: "Junior",
                                SR: "Senior",
                                MG: "Manager",
                                PM: "Project Manager",
                              }[value?.position] || ""}
                            </span>
                          </td>
                          <td className=" py-3 text-right">
                            <span className="text-sm font-semibold font-public-sans text-gray-1">
                              {value?.attendance_status === "P"
                                ? "Present"
                                : value?.attendance_status === "A"
                                ? "Absent"
                                : ""}
                            </span>
                          </td>
                          <td className="text-right py-3">
                            <span className="text-sm font-semibold text-right text-[#04AA77] font-public-sans">
                              {`${TimeFormatterAttendence(
                                value?.check_in_time
                              )}`}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="flex justify-center items-center mt-6">
                {/* <button
                  className="border border-gray-2 rounded-lg font-public-sans py-[7px] px-[12px]
        text-gray-2 text-sm font-semibold cursor-pointer"
                >
                  View all
                </button> */}
              </div>
            </div>
          </RoundedCard>
          <div className=" overflow-x-auto my-6">
            <RoundedCard customPadding={"pt-6"} className={" min-w-[500px]"}>
              <div className=" mb-8 px-6">
                <h1 className=" text-base font-semibold font-epilogue text-gray-1">
                  Leave requests
                </h1>
              </div>
              <div className="max-h-[410px] overflow-y-auto h-fit">
                {pendingLeaves?.map((value, index) => (
                  <div
                    key={index}
                    className={`  flex items-center justify-between border-b-[1px] px-6 py-4`}
                  >
                    <div className="1 flex items-center gap-3">
                      <div className=" border rounded-full w-6 h-6 flex items-center justify-center text-xs font-semibold font-public-sans text-gray-4 ">
                        {index + 1}
                      </div>
                      <div className="flex flex-col gap-3">
                        <h1 className=" text-sm font-semibold font-public-sans text-gray-1">
                          {value?.employee_name}
                        </h1>
                        <h1 className=" text-sm font-normal font-public-sans text-gray-2">
                          {value?.leave_type}
                        </h1>
                      </div>
                    </div>
                    <div className="2 flex flex-col gap-3">
                      <h1 className=" text-sm font-semibold font-public-sans text-gray-1">
                        Date
                      </h1>
                      <h1 className=" text-sm font-normal font-public-sans text-gray-2">
                        {value?.leave_date}
                      </h1>
                    </div>
                    <div className="3 flex flex-col gap-1">
                      <h1 className=" text-sm font-semibold font-public-sans text-gray-1">
                        Leave Status
                      </h1>
                      <div className="flex items-center justify-center">
                        <h1
                          className={` px-[12px] py-[5px] rounded-full ${
                            value.leave_status === "Pending"
                              ? "approved "
                              : "packed"
                          } text-xs font-semibold font-public-sans`}
                        >
                          {value?.leave_status}
                        </h1>
                      </div>
                    </div>
                    <div className="4">
                      <div>
                        <button
                          onClick={() => {
                            setReviewLeaveDialog(true);
                            setReviewData(value);
                          }}
                          className="flex items-center justify-center text-sm font-semibold font-public-sans text-gray-1 px-[12.5px] py-[7px] rounded-lg border border-gray-1 w-[72px] h-[30px]"
                        >
                          Review
                        </button>
                        {reviewLeaveDialog && (
                          <ReviweLeaveDialog
                            takedata={takedata}
                            reviewData={reviewData}
                            show={reviewLeaveDialog}
                            onClose={() => setReviewLeaveDialog(false)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </RoundedCard>
          </div>
        </div>
        <div className=" col-span-12 lg:col-span-4">
          <RoundedCard customPadding={"p-3"}>
            <div className=" calender">
              <Calendar
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
              />
            </div>
            <div className="">
              <div className=" my-6">
                <h1 className=" text-[22px] font-semibold font-public-sans text-[#23272C]">
                  Attendance activity
                </h1>
              </div>
              <div className=" overflow-x-auto h-[50vh] pr-3">
                <div className="today">
                  <div className="my-3">
                    <h1 className=" text-xs font-normal font-public-sans text-[#343942]">
                      Today
                    </h1>
                  </div>
                  <div className="bg-[#e5f7eb] rounded-xl py-4 px-6 flex items-center gap-4">
                    <div>
                      <h1 className=" text-xl font-semibold font-public-sans text-gray-1">
                        {presentSingleEmployees?.check_in_time}
                      </h1>
                    </div>
                    <div className="flex items-center gap-[27px]">
                      <div>
                        <Greenline />
                      </div>
                      <div>
                        <h1 className=" text-[13px] font-medium font-Libre-franklin text-[#4F4F4F]">
                          {presentSingleEmployees?.check_in_time ? (
                            <button>Login</button>
                          ) : (
                            <p>
                             "N/A"
                            </p>
                          )}
                        </h1>
                        <h1 className=" text-lg font-semibold font-public-sans text-gray-1">
                          <div>
                            {presentSingleEmployees?.attendance_status === "P"
                              ? "Present"
                              : presentSingleEmployees?.attendance_status ===
                                "A"
                              ? "Absent"
                              : ""}
                          </div>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="yesterday my-5">
                  <div className="my-3">
                    <h1 className=" text-lg font-medium font-public-sans text-[#343942]">
                      Logout Time
                    </h1>
                  </div>
                  <div className="today">
                  <div className="my-3">
                    <h1 className=" text-xs font-normal font-public-sans text-[#343942]">
                      Today
                    </h1>
                  </div>
                  <div className="bg-[#edf2ff] rounded-xl py-4 px-6 flex items-center gap-4">
                    <div>
                      <h1 className=" text-xl font-semibold font-public-sans text-gray-1">
                        {presentSingleEmployees?.check_out_time}
                      </h1>
                    </div>
                    <div className="flex items-center gap-[27px]">
                      <div>
                        <Blueline />
                      </div>
                      <div>
                        <h1 className=" text-[13px] font-medium font-Libre-franklin text-[#4F4F4F]">
                          {presentSingleEmployees?.check_out_time ? (
                            <button>Log out</button>
                          ) : (
                            <p>
                             "N/A"
                            </p>
                          )}
                        </h1>
                        <h1 className=" text-lg font-semibold font-public-sans text-gray-1">
                          <div>
                          {presentSingleEmployees?.total_hours_worked}
                          </div>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </RoundedCard>
        </div>
      </div>
    </Layout>
  );
}

export default AttendanceAndLeaveHR;
