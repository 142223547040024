import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Svgs from "assets/svgs/Index";
import { useLocation } from "react-router-dom";
import { useSidebarContext } from "Context/ContextLayout.jsx";
import { useUserContext } from "Context/UserProvider";
import { logoutApi } from "api/taskManagement";
import { toast } from "react-toastify";

export const SideBar = ({ active }) => {
  const { sidebarOpen } = useSidebarContext();
  const { user } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [showName, setShowName] = useState(true);

  const userSideBarData = [
    {
      name: "Dashboard",
      icon: (
        <Svgs.Dashboard color={active === "Dashboard" ? "#fff" : "#828282"} />
      ),
      navigate: "/user/dashboard",
      active: active === "Dashboard",
    },
    {
      name: "My Tasks",
      icon: <Svgs.MyTasks color={active === "My Tasks" ? "#fff" : "#828282"} />,
      navigate: "/user/tasks",
      active: active === "My Tasks",
    },
    {
      name: "My Team",
      icon: <Svgs.Team color={active === "My Team" ? "#fff" : "#828282"} />,
      navigate: "/user/my-team",
      active: active === "My Team",
    },
    {
      name: "Chat",
      icon: <Svgs.Chat color={active === "Chat" ? "#fff" : "#828282"} />,
      navigate: "/user/chat",
      active: active === "Chat",
    },
    {
      name: "Attendance & Leave",
      icon: (
        <Svgs.AttendanceSvg
          color={active === "Attendance & Leave" ? "#fff" : "#828282"}
        />
      ),
      navigate: "/user/attendance-leave",
      active: active === "Attendance & Leave",
    },
    {
      name: "My profile",
      icon: (
        <Svgs.Setting color={active === "My profile" ? "#fff" : "#828282"} />
      ),
      navigate: "/user/my-profile",
      active: active === "My profile",
    },
  ];

  const hrSideBarData = [
    {
      name: "Dashboard",
      icon: (
        <Svgs.Dashboard color={active === "Dashboard" ? "#fff" : "#828282"} />
      ),
      navigate: "/hr/dashboard",
      active: active === "Dashboard",
    },
    {
      name: "Employees",
      icon: <Svgs.Team color={active === "Employees" ? "#fff" : "#828282"} />,
      navigate: "/hr/employees",
      active: active === "Employees",
    },
    {
      name: "Applicants",
      icon: (
        <Svgs.Applicantssvg
          color={active === "Applicants" ? "#fff" : "#828282"}
        />
      ),
      navigate: "/hr/applicants",
      active: active === "Applicants",
    },
    {
      name: "Attendance & Leave",
      icon: (
        <Svgs.AttendanceSvg
          color={active === "Attendance & Leave" ? "#fff" : "#828282"}
        />
      ),
      navigate: "/hr/attandence",
      active: active === "Attendance & Leave",
    },
    {
      name: "Payroll",
      icon: (
        <Svgs.Payrollsvg color={active === "Payroll" ? "#fff" : "#828282"} />
      ),
      navigate: "/hr/payroll",
      active: active === "Payroll",
    },
    {
      name: "Chat",
      icon: <Svgs.Chat color={active === "Chat" ? "#fff" : "#828282"} />,
      navigate: "/hr/chat",
      active: active === "Chat",
    },
    {
      name: "Documents",
      icon: (
        <Svgs.Document color={active === "Documents" ? "#fff" : "#828282"} />
      ),
      navigate: "/hr/documents",
      active: active === "Documents",
    },
    {
      name: "My profile",
      icon: (
        <Svgs.Setting color={active === "My profile" ? "#fff" : "#828282"} />
      ),
      navigate: "/hr/myprofile",
      active: active === "My profile",
    },
  ];

  const handleSideBarClick = (path) => {
    navigate(path);
  };

  const logOutFunction = async () => {
    try {
      //   localStorage.getItem("token"); // Retrieve token from localStorage
      const response = await logoutApi(); // Call the API
      console.log("apiresponse", response);
      toast.success(response?.response?.message, {
        autoClose: 3000,
      });

      if (response?.success) {
        localStorage.removeItem("token"); // Remove token after successful logout
        localStorage.removeItem("user"); // Clear the user info if needed
        localStorage.removeItem("userId");
        localStorage.removeItem("tokenExpiry");
        navigate("/"); // Navigate to login screen
      }
    } catch (error) {
      console.error("Logout error:", error);

      toast.error(error?.response?.data?.response?.message, {
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="flex flex-col relative left-0 bg-white py-8 px-3 border-r-2 w-full h-full items-center justify-between ">
      <div className="w-full flex flex-col ">
        {(user.role === "hr" ? hrSideBarData : userSideBarData).map(
          (value, index) => (
            <div
              key={index}
              onClick={() => {
                handleSideBarClick(value.navigate);
              }}
              className={`${
                value.active ? " bg-btn-gradient " : "bg-white"
              } p-4 rounded-lg flex flex-row space-x-4 w-full cursor-pointer`}
            >
              <p>{value.icon}</p>
              <p
                className={`${
                  value.active
                    ? "text-white text-base font-normal font-public-sans"
                    : "text-gray-4 text-base font-normal font-public-sans"
                }`}
              >
                {sidebarOpen && showName ? value.name : ""}
              </p>
            </div>
          )
        )}
      </div>

      <div
        onClick={logOutFunction}
        className={`bg-white cursor-pointer rounded-lg flex items-center ${
          sidebarOpen ? "justify-start px-4 gap-4" : "justify-center"
        } flex-row text-[#828282] w-full`}
      >
        <Svgs.Logout />
        {sidebarOpen && showName && <p>Logout</p>}
      </div>
    </div>
  );
};
