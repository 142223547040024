// import React from "react";
// import { useFormik } from "formik";
// import * as Yup from "yup"; // For validation schema
// import FileUpload from "components/common/file-upload/file-upload"; // Assuming you have a custom file upload component

// const ActiveStep3 = ({
//   prevsStep,
//   activeStep,
//   handleNextClick,
//   steps,
//   handleShowPopup,
//   setAllFormData,
//   allFormData,
// }) => {
//   const formik = useFormik({
//     initialValues: {
//       offerLetter: null,
//       experienceLetter: null,
//       graduationCertificate: null,
//       additionalCertificate: null,
//     },
//     validationSchema: Yup.object({
//       offerLetter: Yup.mixed().required("Offer letter is required"),
//       experienceLetter: Yup.mixed().required("Experience letter is required"),
//       graduationCertificate: Yup.mixed().required(
//         "Graduation certificate is required"
//       ),
//     }),
//     onSubmit: (values) => {
//       setAllFormData((prev) => ({
//         ...prev,
//         ...values,
//       })); // Update all form data
//       console.log("Form Submitted with data:", values);
//     },
//   });

//   const handleFileChange = (fieldName, file) => {
//     // Set the file name in the formik state, not the file itself
//     formik.setFieldValue(fieldName, file ? file.name : null);
//   };

//   const handleNextStep = async () => {
//     // Mark all fields as touched
//     formik.setTouched({
//       offerLetter: true,
//       experienceLetter: true,
//       graduationCertificate: true,
//       additionalCertificate: true,
//     });

//     // Validate the form
//     const errors = await formik.validateForm();

//     if (Object.keys(errors).length === 0) {
//       // No validation errors, update the form data
//       setAllFormData((prev) => ({
//         ...prev,
//         ...formik.values,
//       }));

//       if (activeStep === 5) {
//         formik.submitForm(); // Submit form on the last step
//       } else {
//         handleNextClick(); // Move to the next step
//       }
//     }
//   };

//   return (
//     <div className="px-6 flex flex-col gap-[24px] w-full max-h-[650px]">
//       <form onSubmit={formik.handleSubmit}>
//         {/* Offer Letter File Upload */}
//         <FileUpload
//           label="Offer letter"
//           name="offerLetter"
//           onChange={(file) => handleFileChange("offerLetter", file)}
//           error={formik.touched.offerLetter && formik.errors.offerLetter}
//         />
//         {formik.values.offerLetter && (
//           <div className="text-sm text-gray-600 mt-2">
//             {formik.values.offerLetter} {/* Display only the file name */}
//           </div>
//         )}

//         {/* Experience Letter File Upload */}
//         <FileUpload
//           label="Experience letter"
//           name="experienceLetter"
//           onChange={(file) => handleFileChange("experienceLetter", file)}
//           error={
//             formik.touched.experienceLetter && formik.errors.experienceLetter
//           }
//         />
//         {formik.values.experienceLetter && (
//           <div className="text-sm text-gray-600 mt-2">
//             {formik.values.experienceLetter} {/* Display only the file name */}
//           </div>
//         )}

//         {/* Graduation Certificate File Upload */}
//         <FileUpload
//           label="Graduation certificate"
//           name="graduationCertificate"
//           onChange={(file) =>
//             handleFileChange("graduationCertificate", file)
//           }
//           error={
//             formik.touched.graduationCertificate &&
//             formik.errors.graduationCertificate
//           }
//         />
//         {formik.values.graduationCertificate && (
//           <div className="text-sm text-gray-600 mt-2">
//             {formik.values.graduationCertificate} {/* Display only the file name */}
//           </div>
//         )}

//         {/* Additional Certificate File Upload */}
//         <FileUpload
//           label="Additional certificate"
//           name="additionalCertificate"
//           onChange={(file) =>
//             handleFileChange("additionalCertificate", file)
//           }
//           error={
//             formik.touched.additionalCertificate &&
//             formik.errors.additionalCertificate
//           }
//         />
//         {formik.values.additionalCertificate && (
//           <div className="text-sm text-gray-600 mt-2">
//             {formik.values.additionalCertificate} {/* Display only the file name */}
//           </div>
//         )}

//         {/* Step Navigation Buttons */}
//         <div className="flex justify-center mt-[32px] gap-[10px] pb-[30px]">
//           <button
//             type="button"
//             onClick={prevsStep}
//             disabled={activeStep === 0}
//             className={`${
//               activeStep === 0 ? "hidden" : ""
//             } w-[162px] py-[14px] px-[24px] font-mulish 
//             border rounded-lg border-[#f65154] font-bold lg:text-base text-sm text-transparent bg-clip-text bg-btn-gradient`}
//           >
//             Back
//           </button>

//           <button
//             type="button"
//             onClick={handleNextStep}
//             className="lg:text-base w-[162px] text-sm font-mulish font-bold 
//             py-[14px] px-[24px] bg-btn-gradient text-white rounded-lg
//             shadow-sm hover:bg-red-600 focus:outline-none"
//           >
//             {activeStep === 5 ? "Submit" : "Next"}
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default ActiveStep3;
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup"; // For validation schema
import FileUpload from "components/common/file-upload/file-upload"; // Assuming you have a custom file upload component

const ActiveStep3 = ({
  prevsStep,
  activeStep,
  handleNextClick,
  steps,
  handleShowPopup,
  setAllFormData,
  allFormData,
}) => {
  const formik = useFormik({
    initialValues: {
      offerLetter: null,
      experienceLetter: null,
      graduationCertificate: null,
      additionalCertificate: null,
    },
    validationSchema: Yup.object({
      offerLetter: Yup.mixed().required("Offer letter is required"),
      experienceLetter: Yup.mixed().required("Experience letter is required"),
      graduationCertificate: Yup.mixed().required(
        "Graduation certificate is required"
      ),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          formData.append(key, values[key]); // Append file objects as binary
        }
      });

      console.log("Form Submitted with data:", formData);

      // Simulate form data submission
      setAllFormData((prev) => ({
        ...prev,
        ...values,
      }));
    },
  });

  const handleFileChange = (fieldName, file) => {
    // Set the actual file object in formik state
    formik.setFieldValue(fieldName, file || null);
  };

  const handleNextStep = async () => {
    // Mark relevant fields as touched
    formik.setTouched({
      offerLetter: true,
      experienceLetter: true,
      graduationCertificate: true,
    });

    // Validate the form
    const errors = await formik.validateForm();

    if (Object.keys(errors).length === 0) {
      // No validation errors, update the form data
      setAllFormData((prev) => ({
        ...prev,
        ...formik.values,
      }));

      if (activeStep === 5) {
        formik.submitForm(); // Submit form on the last step
      } else {
        handleNextClick(); // Move to the next step
      }
    }
  };

  return (
    <div className="px-6 flex flex-col gap-[24px] w-full max-h-[650px]">
      <form onSubmit={formik.handleSubmit}>
        {/* Offer Letter File Upload */}
        <FileUpload
          label="Offer letter"
          name="offerLetter"
          onChange={(file) => handleFileChange("offerLetter", file)}
          error={formik.touched.offerLetter && formik.errors.offerLetter}
        />
        {formik.values.offerLetter && (
          <div className="text-sm text-gray-600 mt-2">
            {formik.values.offerLetter.name} {/* Display only the file name */}
          </div>
        )}

        {/* Experience Letter File Upload */}
        <FileUpload
          label="Experience letter"
          name="experienceLetter"
          onChange={(file) => handleFileChange("experienceLetter", file)}
          error={
            formik.touched.experienceLetter && formik.errors.experienceLetter
          }
        />
        {formik.values.experienceLetter && (
          <div className="text-sm text-gray-600 mt-2">
            {formik.values.experienceLetter.name} {/* Display only the file name */}
          </div>
        )}

        {/* Graduation Certificate File Upload */}
        <FileUpload
          label="Graduation certificate"
          name="graduationCertificate"
          onChange={(file) =>
            handleFileChange("graduationCertificate", file)
          }
          error={
            formik.touched.graduationCertificate &&
            formik.errors.graduationCertificate
          }
        />
        {formik.values.graduationCertificate && (
          <div className="text-sm text-gray-600 mt-2">
            {formik.values.graduationCertificate.name} {/* Display only the file name */}
          </div>
        )}

        {/* Additional Certificate File Upload */}
        <FileUpload
          label="Additional certificate"
          name="additionalCertificate"
          onChange={(file) =>
            handleFileChange("additionalCertificate", file)
          }
        />
        {formik.values.additionalCertificate && (
          <div className="text-sm text-gray-600 mt-2">
            {formik.values.additionalCertificate.name} {/* Display only the file name */}
          </div>
        )}

        {/* Step Navigation Buttons */}
        <div className="flex justify-center mt-[32px] gap-[10px] pb-[30px]">
          <button
            type="button"
            onClick={prevsStep}
            disabled={activeStep === 0}
            className={`${
              activeStep === 0 ? "hidden" : ""
            } w-[162px] py-[14px] px-[24px] font-mulish 
            border rounded-lg border-[#f65154] font-bold lg:text-base text-sm text-transparent bg-clip-text bg-btn-gradient`}
          >
            Back
          </button>

          <button
            type="button"
            onClick={handleNextStep}
            className="lg:text-base w-[162px] text-sm font-mulish font-bold 
            py-[14px] px-[24px] bg-btn-gradient text-white rounded-lg
            shadow-sm hover:bg-red-600 focus:outline-none"
          >
            {activeStep === 5 ? "Submit" : "Next"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ActiveStep3;
