import React, { useState, useEffect, useRef } from "react";
import SuccessfulDialog from "../../../components/Elements/SuccessfulDialog";
import { Input } from "../../../components/Input";
import Button from "../../../components/Button.jsx";
import svgs from "../../../assets/svgs/Index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { applyOfficeTimeApi, officeTimeLeaveApi } from "api/taskManagement"; // Add your POST API function here

// Validation schema using Yup
const validationSchema = Yup.object({
  startTime: Yup.string().required("Start time is required"),
  endTime: Yup.string().required("End time is required"),
});

function OfficeTimeDialog({ show, onClose }) {
  const [showPopup, setShowPopup] = useState(false);

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    onClose();
  };

  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (show || showPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show, showPopup]);

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  if (!show) return null;

  // Handle form submission
  const handleFormSubmit = async (values) => {
    console.log("Form Values:", values);
    const formData = new FormData();
    formData.append("start_time", values.startTime);
    formData.append("end_time", values.endTime);

    try {
      const response = await officeTimeLeaveApi(formData);
      console.log("API Response:", response);

      if (response.success) {
        handleShowPopup();
      } else {
        alert("Failed to update office time.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error occurred while updating office time.");
    }
  };

  return (
    <>
      {!showPopup ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="py-3 ">
            <div
              ref={popupRef}
              className="bg-white shadow-lg rounded-xl border p-6 mx-2 overflow-y-auto h-full  md:w-[598px]"
            >
              <div className="flex flex-col gap-3">
                <div className="flex items-center justify-center text-center">
                  <h1 className="text-xl md:text-2xl font-semibold font-public-sans text-gray-700">
                    Office Time
                  </h1>
                </div>

                {/* Formik Form */}
                <Formik
                  initialValues={{ startTime: "", endTime: "" }}
                  validationSchema={validationSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({ values, handleChange, errors, touched }) => (
                    <Form>
                      <div>
                        <label
                          htmlFor="startTime"
                          className="text-sm font-bold text-gray-700"
                        >
                          Start Time
                        </label>
                        <Field
                          type="time"
                          name="startTime"
                          className="w-full mt-2 border rounded px-4 py-[10.5px]"
                        />
                        {errors.startTime && touched.startTime && (
                          <div className="text-red-500 text-sm">
                            {errors.startTime}
                          </div>
                        )}
                      </div>

                      <div className="mt-4">
                        <label
                          htmlFor="endTime"
                          className="text-sm font-bold text-gray-700"
                        >
                          End Time
                        </label>
                        <Field
                          type="time"
                          name="endTime"
                          className="w-full mt-2 border rounded px-4 py-[10.5px]"
                        />
                        {errors.endTime && touched.endTime && (
                          <div className="text-red-500 text-sm">
                            {errors.endTime}
                          </div>
                        )}
                      </div>

                      <div className="flex items-center justify-center gap-4 mt-6">
                        <Button
                          type="submit"
                          text="Apply"
                          className="w-[162px] h-[48px]"
                          customPadding="py-[14px] px-[24px]"
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SuccessfulDialog
          heading="Time Updated successfully"
          show={showPopup}
          onClose={handleClosePopup}
        />
      )}
    </>
  );
}

export default OfficeTimeDialog;
