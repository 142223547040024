import React, { useState, useEffect, useRef } from "react";
import SuccessfulDialog from "../../../../components/Elements/SuccessfulDialog";
import { approveleaveApi } from "api/taskManagement";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function ReviweLeaveDialog({ show, onClose, reviewData, takedata }) {
  const [showPopup, setShowPopup] = useState(false);
  const [isvisible, setIsVisible] = useState(true);
  const [statusAttandence, setStatusAttandence] = useState("");
  // console.log("statusAttandence===>>>>", statusAttandence);
  // Stat e to store approval/rejection status

  console.log("reviewData123", reviewData);
  const { id } = useParams();
  console.log("id=====>params", id);

  console.log("showPopUpModal", showPopup);

  useEffect(() => {
    console.log("statusAttandence", statusAttandence);
  }, [statusAttandence]);

  const handleLeaveSubmit = async (status) => {
    const formData = new FormData();

    formData.append("leave_status", status);
    formData.append("leave_date", reviewData?.leave_date);

    try {
      const response = await approveleaveApi(reviewData.id, formData);
      console.log("response==>approved", response);
      toast.success(response?.response?.message, {
        autoClose: 3000,
      });
      console.log("Response:", response);
      onClose();
      takedata();
    } catch (error) {
      console.log("Error fetching data:", error);
      toast.error(error?.response?.message, {
        autoClose: 3000,
      });
    }
  };

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    onClose();
  };

  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (show || showPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show, showPopup]);

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  const handleApproval = () => {
    console.log("accepted"); // Log accepted message
    setStatusAttandence("accepted"); // Update the status state
    // You can now call the PATCH API with the status
  };

  const handleRejection = () => {
    console.log("rejected"); // Log rejected message
    setStatusAttandence("rejected"); // Update the status state
    // You can now call the PATCH API with the status
  };

  if (!show) return null;

  return (
    <>
      {!showPopup ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="flex justify-center items-center">
            <div
              // ref={popupRef}
              className="bg-white shadow-lg rounded-xl border p-6 w-[90vw] md:w-[50vw] overflow-y-auto h-[70vh] grocery-scrollbar-none"
            >
              <div className="flex items-center justify-center">
                <h1 className="text-2xl font-semibold font-public-sans text-gray-1">
                  Review leave
                </h1>
              </div>
              <div className="my-[48px] flex items-center justify-between">
                <div>
                  <div>
                    <h1 className="text-base font-bold font-mulish text-gray-1">
                      Sent by
                    </h1>
                  </div>
                  <div className="flex items-center gap-4 my-[14.5px]">
                    <img src="/images/anna.png" alt="" />
                    <span className="text-base font-semibold font-public-sans text-gray-1">
                      {reviewData?.employee_name}
                    </span>
                  </div>
                </div>
                <div>
                  <button className="bg-[#f6f6f6] rounded-xl border px-[12px] py-[5.5px] text-base font-normal font-mulish text-gray-1 flex items-center justify-center">
                    {reviewData?.leave_type}
                  </button>
                </div>
              </div>
              <div>
                <div>
                  <h1 className="text-base font-bold font-mulish text-gray-1">
                    Reason for leave
                  </h1>
                </div>
                <div className="px-[24px] py-[14px] rounded-xl border my-2">
                  <h1 className="text-xs md:text-base font-normal font-mulish text-gray-1">
                    Dear Manager,
                  </h1>
                  <h1 className="text-xs md:text-base font-normal font-mulish text-gray-1 my-5">
                    {reviewData?.description}
                  </h1>
                  <h1 className="text-xs md:text-base font-normal font-mulish text-gray-1 my-5">
                    Thank you for your understanding.
                  </h1>
                  <h1 className="text-xs md:text-base font-normal font-mulish text-gray-1 w-[50%] sm:w-[30%]">
                    Best regards {reviewData?.employee_name}
                  </h1>
                </div>
              </div>
              <div className="flex items-center justify-center gap-4 mt-[48px]">
                <button
                  onClick={() => {
                    console.log("Clicked Refuse");
                    setStatusAttandence("rejected");
                    handleLeaveSubmit("rejected");
                  }}
                  className="px-[24px] py-[14.5px] text-base font-bold font-mulish text-gray-2"
                >
                  Refuse
                </button>
                <button
                  onClick={() => {
                    console.log("Clicked Approve");
                    setStatusAttandence("accepted");
                    handleLeaveSubmit("accepted");
                  }}
                  className="flex items-center justify-center h-[48px] px-[20px] py-[14.5px] text-base font-bold font-mulish text-gray-2 border rounded-xl hover:transition-all hover:scale-105"
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SuccessfulDialog
          heading={"Leave approved"}
          show={showPopup}
          onClose={handleClosePopup}
        />
      )}
    </>
  );
}

export default ReviweLeaveDialog;
