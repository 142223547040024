import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  Attdenence,
  Calenderhr,
  Chair,
  Employs,
  Threedots,
} from "assets/svgs/Index";
import { Layout } from "components/Layout/DashboardLayout";
import RoundedCard from "components/Elements/RoundedCard";
import Button from "components/Button";
import DashboardCard from "components/Elements/DashboardCard";
import MoreMeetoptions from "./MoreMeetoptions";
import SelectDropdown from "components/SelectDropdown";
import CustomDropdown from "components/CustomDropdown";
import VerifiedSuccessful from "./CreateAMeeting";
import {
  attendance,
  attendance_Card,
} from "Pages/HRdata/Dashboaed/AttandenceCard";
import { useUserContext } from "Context/UserProvider";
import {
  attendanceEmployeeApi,
  attendanceSummaryApi,
  presentEmployeeslistApi,
} from "api/taskManagement";
import { timeFormatter } from "utils/timeFormatter";

function DashboardHR() {
  const [showPopup, setShowPopup] = useState(false);
  const { user } = useUserContext();

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const [attendanceHR, setAttendance] = useState([attendance]);

  const [taskCompletion, setTaskCompletion] = useState({
    expectedScore: 100,
    achievedScore: 82,
    completedTasks: 46,
  });

  const [meetings, setMeetings] = useState([
    {
      title: "Daily meeting",
      time: "2:30 PM",
      border: "border-gray-5",
      border_b: "border-b",
    },
    {
      title: "Daily meeting",
      time: "2:30 PM",
      border: "border-gray-5",
      border_b: "border-b",
    },
    { title: "Daily meeting", time: "2:30 PM" },
  ]);

  const attendanceData = {
    attendancePercentage: 98,
    present: 24,
    onLeave: 0,
    absent: 1,
  };

  const meetingCard = [
    {
      meeting: "Daily meeting",
      time: "2:30 PM",
      border_b: "border-b-2",
    },
    {
      meeting: "Daily meeting",
      time: "2:30 PM",
      border_b: "border-b-2",
    },
    {
      meeting: "Daily meeting",
      time: "2:30 PM",
    },
    {
      meeting: "Daily meeting",
      time: "2:30 PM",
    },
    {
      meeting: "Daily meeting",
      time: "2:30 PM",
    },
    {
      meeting: "Daily meeting",
      time: "2:30 PM",
    },
  ];

  const roleOptions = [
    { value: "python developer", label: "Python developer" },
    { value: "web designer", label: "Web designer" },
    { value: "ui ux designer", label: "UI UX designer" },
    { value: "Web developer", label: "Web developer" },
  ];

  const frequencyOptions = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
  ];

  const handleFrequencyChange = (e) => {
    console.log("Selected frequency:", e.target.value);
  };
  const [showPopups, setShowPopups] = useState(false);
  const [popupsindex, setPopupsindex] = useState(null);

  const handleShowPopups = (index) => {
    setShowPopups(true);
    setPopupsindex(index);
  };

  const handleClosePopups = (index) => {
    setShowPopups(false);
    setPopupsindex(index);
  };

  const [attandencetime, setAttandencetime] = useState();

  console.log("attandencetime", attandencetime);

  useEffect(() => {
    const takedata = async () => {
      try {
        const response = await attendanceEmployeeApi();
        console.log("responsingle==>>>", response);
        setAttandencetime(response.response); // Store the response data in state
      } catch (error) {
        console.log("error fetching data:", error);
      }
    };
    takedata();
  }, []);

  const [attendancesummary, setAttendancesummary] = useState();

  console.log("attendancesummary=======>", attendancesummary);

  useEffect(() => {
    const takedata = async () => {
      try {
        const response = await attendanceSummaryApi();
        console.log("attendancesummary==>>>", response);
        setAttendancesummary(response.response); // Store the response data in state
      } catch (error) {
        console.log("error fetching data:", error);
      }
    };
    takedata();
  }, []);

  const [presentEmployees, setPresentEmployees] = useState();

  console.log("attandencetime", presentEmployees);

  useEffect(() => {
    const takedata = async () => {
      try {
        const response = await presentEmployeeslistApi();
        console.log("presentEmloyee==>>>", response);
        setPresentEmployees(response.response.present_employees); // Store the response data in state
      } catch (error) {
        console.log("error fetching data:", error);
      }
    };
    takedata();
  }, []);

  return (
    <>
      <Layout active={"Dashboard"}>
        <div className=" h-full overflow-scroll">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 sm:px-4  lg:px-0">
            <DashboardCard
              tasks={"Total Employee"}
              value={`${attendancesummary?.total_employee}`}
              tasksCount={"0"}
              taskcountclass={`text-green-600 `}
              svg={<Employs />}
              employees={"new employees"}
              employeesclass={`text-gray-4`}
            />
            <DashboardCard
              tasks={"Vacancies"}
              value={"0"}
              tasksCount={"0"}
              taskcountclass={`text-[#F4A012]`}
              svg={<Chair />}
              employees={"website designers"}
              employeesclass={`text-[#F4A012]`}
            />
            <DashboardCard
              tasks={"Employees Attendence"}
              value={`${attendancesummary?.attendance_percentage}%`}
              tasksCount={presentEmployees?.length}
              taskcountclass={`text-[#00B037]`}
              svg={<Attdenence />}
              employees={"present today"}
              employeesclass={`text-gray-4`}
            />
            <DashboardCard
              tasks={"My Attendence"}
              value={`${attandencetime?.attendance_percentage}%`}
              valueclass={`!text-[#00B037]`}
              tasksCount={"Log in:"}
              taskcountclass={`text-gray-4`}
              svg={<Attdenence />}
              employees={`${timeFormatter(
                attandencetime?.check_in_time_today
              )} today`}
              employeesclass={`text-gray-2`}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mt-4 sm:px-4  lg:px-0">
            <RoundedCard className={"max-h-[770px] h-fit"}>
              <div className="">
                <h3 className="text-lg text-gray-1 text-[16px] font-public-sans font-semibold">
                  Attendance
                </h3>
                {/* svg for circle bar */}

                <div className="flex  sm:gap-[23px] gap-[10px] items-center p-4 bg-[#f6f6f6] rounded-lg  mt-6  ">
                  <div className="relative">
                    <div className="w-[140px] h-[140px]">
                      <CircularProgressbar
                        value={attendancesummary?.attendance_percentage}
                        strokeWidth="12"
                        styles={buildStyles({
                          pathColor: "#00b037",
                          textColor: "#000",
                        })}
                      />
                    </div>
                    <div className="absolute inset-0 flex items-center justify-center flex-col ">
                      <span className="text-[30px] font-bold text-[#333333] font-public-sans">
                        {attendancesummary?.attendance_percentage}%
                      </span>
                      <p className="text-gray-4 font-public-sans text-[12px] ">
                        attendance
                      </p>
                    </div>
                  </div>
                  <div className="w-full  ">
                    <div className="flex justify-between items-center  border-[#E0E0E0] border-b py-[8px]">
                      <h6 className="font-public-sans text-[12px] text-[#4F4F4F] ">
                        Present
                      </h6>{" "}
                      <span>
                        <h6 className="font-public-sans text-[14px] text-[#333333]">
                          {attendancesummary?.total_present}
                        </h6>
                      </span>
                    </div>
                    <div className="flex justify-between items-center border-[#E0E0E0] border-b py-[8px]">
                      <h6 className="font-public-sans text-[12px] text-[#4F4F4F] ">
                        On leave
                      </h6>{" "}
                      <span>
                        <h6 className=" font-public-sans text-[14px] text-[#333333]">
                          {attendancesummary?.total_on_leave}
                        </h6>
                      </span>
                    </div>
                    <div className="flex justify-between items-center  py-[8px]">
                      <h6 className="font-public-sans text-[12px] text-[#4F4F4F] ">
                        Absent
                      </h6>{" "}
                      <span>
                        <h6 className="font-public-sans text-[14px] text-[#333333]">
                          {attendancesummary?.total_absent}
                        </h6>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="overflow-auto  overflow-y-auto max-h-[500px] h-fit">
                  {presentEmployees?.map((person, index) => (
                    <div
                      key={index}
                      className="flex justify-between   w-full  mt-4  border-gray-5 border-b py-[23px]   overflow-auto items-center min-w-[450px] "
                    >
                      <div className="flex items-center w-full gap-[14px]">
                        {person?.upload_image ? (
                          <img
                            src={person.upload_image}
                            alt="avatar"
                            className="w-[42px] h-[42px] rounded-full mr-2"
                          />
                        ) : (
                          <div className="w-[42px] h-[42px] rounded-full mr-2 bg-green-300 flex items-center justify-center">
                            {/* Optional: Add fallback text or icon */}
                          </div>
                        )}
                        <div>
                          <p className="text-gray-1 text-[14px] font-medium font-public-sans">
                            {person?.full_name || "N/A"}
                          </p>

                          <h1 className="text-[#00B037] text-[14px] font-medium font-public-sans">
                            {person.working}
                          </h1>
                        </div>
                      </div>
                      <div className="w-full ">
                        {" "}
                        <p className="text-gray-1  text-[14px] font-medium font-public-sans">
                          {person?.position}
                        </p>
                      </div>
                      <div className="w-full">
                        <p className="text-gray-1 text-[14px] font-medium font-public-sans">
                          {person?.attendance_status === "P"
                            ? "Present"
                            : "Absent"}
                        </p>
                      </div>
                      <div className="">
                        <p className="text-[#04AA77] text-[14px] font-medium font-public-sans">
                          {person?.check_in_time}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-center items-center mt-2">
                  {/* <button className="mt-4 text-gray-2 border border-gray-2  font-semibold font-public-sans text-[14px] py-[7px] px-[12px] rounded-lg ">
                    View all
                  </button> */}
                </div>
              </div>
            </RoundedCard>

            <div className="  ">
              <RoundedCard>
                <div className="">
                  <div className="">
                    <div className="">
                      <div className="flex sm:justify-between items-center mb-4  flex-wrap  justify-center m-auto gap-[15px]">
                        <h2 className="text-sm md:text-base font-semibold font-public-sans text-gray-1">
                          Task completion score
                        </h2>
                        <CustomDropdown
                          label="Weekly"
                          options={frequencyOptions}
                          name="frequency"
                          id="frequency"
                          onChange={handleFrequencyChange}
                          className=""
                          customWidth="w-40"
                        />
                      </div>
                    </div>
                    {/* foe seected tG */}

                    <div className="flex justify-center items-center flex-col gap-[10px]">
                      <div>
                        {/* <select
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e.target.value)}
                          className=" focus:outline-none lg:text-[16px] text-[14px] font-public-sans  gap-[10px]"
                        >
                          {roles.map((role) => (
                            <option key={role} value={role}>
                              {role}
                            </option>
                          ))}
                        </select> */}
                        <SelectDropdown
                          label=""
                          options={roleOptions}
                          name="role"
                          id="role"
                          selectClass={"w-full"}
                          className={"w-[175px]"}
                        />
                      </div>
                      <div className="text-[#497CFF] cursor-pointer text-[12px] font-public-sans">
                        5 employees
                      </div>
                    </div>

                    <div className="flex flex-col  lg:flex-row items-center justify-between mx-10 my-10 md:my-12 gap-[18px] ">
                      {/* Expected Score */}
                      <div className="flex flex-col items-center relative">
                        <div className="w-36 h-36">
                          <CircularProgressbar
                            value={0}
                            strokeWidth="12"
                            styles={buildStyles({
                              pathColor: "#3b82f6",
                              textColor: "#000",
                            })}
                          />
                        </div>
                        <div className="absolute inset-0 flex items-center justify-center flex-col ">
                          <span className="text-2xl font-bold text-[#333333] font-public-sans">
                            0%
                          </span>
                          <p className="text-gray-4 font-public-sans text-[12px] ">
                            Expected score
                          </p>
                        </div>
                      </div>

                      {/* Achieved Score */}
                      <div className="flex flex-col items-center relative">
                        <div className="w-36 h-36">
                          <CircularProgressbar
                            value={0}
                            strokeWidth="12"
                            styles={buildStyles({
                              pathColor: "#f59e0b",
                              textColor: "#000",
                              trailColor: "#f3f4f6",
                            })}
                          />
                        </div>
                        <div className="absolute inset-0 flex items-center justify-center flex-col ">
                          <span className="text-2xl font-bold text-[#333333] font-public-sans">
                            0%
                          </span>
                          <p className="text-gray-4 font-public-sans text-[12px] ">
                            Achieved score
                          </p>
                        </div>
                        <div className="absolute -bottom-14 flex items-center justify-center flex-col gap-2">
                          <p className="text-[13px] font-bold text-gray-1 font-epilogue">
                            0
                          </p>
                          <p className="text-[10px] font-bold font-public-sans text-gray-2">
                            Completed Task
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between items-center mx-3 lg:mx-10 my-2">
                      <div className="flex items-center space-x-2">
                        {/* <img src="images/playbutton.png" alt="" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </RoundedCard>

              <RoundedCard className={"my-6"}>
                <div className="">
                  <div className="flex justify-between items-center static z-30">
                    <h3 className="md:text-[16px] text-[14px] text-gray-1 font-semibold font-public-sans">
                      Meetings
                    </h3>

                    <Button
                      text={"Create new meeting"}
                      customPadding={`py-[8px] px-[16px] text-sm`}
                      handleClick={handleShowPopup} // Call handleShowPopup on button click
                    />

                    {/* Conditionally render VerifiedSuccessful popup */}
                    {showPopup && (
                      <VerifiedSuccessful
                        show={showPopup}
                        onClose={handleClosePopup} // Close popup
                      />
                    )}
                  </div>
                  <div className="flex items-center justify-between"></div>
                  <div className="overflow-auto overflow-y-auto h-[240px]">
                    {meetingCard.map((value, index) => {
                      return (
                        <>
                          <div
                            key={index}
                            className={`${value.border_b}  min-w-[450px] flex items-center gap-3  justify-between   pb-3 mt-7   `}
                          >
                            <div className="flex items-center gap-3">
                              <img src="/images/image 39 (1).png" alt="" />
                              <h1 className=" md:text-[14px] text-[12px] md:text-sm font-epilogue font-semibold text-gray-1">
                                {value.meeting}
                              </h1>
                            </div>
                            <div>
                              <h1 className="md:text-[14px] text-[12px] md:text-sm font-epilogue font-semibold text-gray-1">
                                {value.time}
                              </h1>
                            </div>

                            <div className="flex items-center gap-3">
                              <div className="flex items-center">
                                <img
                                  className="-mr-2"
                                  src="/images/meeting1.png"
                                  alt=""
                                />
                                <img
                                  className="-mr-2"
                                  src="/images/meeting2.png"
                                  alt=""
                                />
                                <img src="/images/meeting3.png" alt="" />
                              </div>
                              <div
                                onClick={() => {
                                  handleShowPopups(index);
                                }}
                                className="cursor-pointer relative"
                              >
                                <Threedots />
                                {/* {popupsindex === index && showPopups && (<MoreMeetoptions show={showPopups} onClose={handleClosePopups} />)} */}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </RoundedCard>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default DashboardHR;
