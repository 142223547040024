import { Fileattachsvg } from "assets/svgs/Index";
import { useState } from "react";

const FileUpload = ({ label, name, onChange, error }) => {
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log(`${label} uploaded:`, file.name);
      // Update Formik field value
      onChange(file);
      handleImageChange(e); // Display a preview
    }
  };

  return (
    <div className="flex flex-col">
      <div>
        <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
          {label}
        </label>
      </div>
      <div className="flex px-[16px] py-[12.5px] border border-gray-500 rounded-lg shadow-sm mt-[8px]">
        <input
          type="file"
          onChange={handleFileChange}
          className="hidden"
          id={label.replace(/\s+/g, "-").toLowerCase()}
        />
        <label
          htmlFor={label.replace(/\s+/g, "-").toLowerCase()}
          className="flex-grow cursor-pointer text-gray-500"
        >
          Upload
        </label>
        <label
          htmlFor={label.replace(/\s+/g, "-").toLowerCase()}
          className="cursor-pointer text-red-500"
        >
          <Fileattachsvg />
        </label>
      </div>
      {error && <div className="text-sm text-red-500 mt-1">{error}</div>}
      {image && (
        <div className="mt-2">
          <img src={image} alt="Preview" className="w-32 h-32 object-cover" />
        </div>
      )}
    </div>
  );
};

export default FileUpload;
